import { Component, OnInit , Input,Output,EventEmitter  } from '@angular/core';
import * as $ from 'jquery';
import { UserService } from 'src/app/services/user.service';
import { WebStorageService } from 'src/app/services/web-storage.service';
import { UserRole } from '../../shared/enum';

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.sass']
})
export class DropdownMenuComponent implements OnInit {
  status: boolean;
  userName: string;
  isMigrationAdmin: boolean = false;
  @Output() notifyParent: EventEmitter<any> = new EventEmitter();
  constructor(private userService: UserService, private webStorageService: WebStorageService) { }

  ngOnInit(): void {
     
    const currentToken = this.webStorageService.getCurrentUserToken();
    if(currentToken){
      this.userName = currentToken.userName;
    }
   
    if (currentToken.role == UserRole.enumAdmin)
      this.isMigrationAdmin = true;
    else
      this.isMigrationAdmin = false;

    $(document).on('click', function () {
      $('.dropdownmenu-menu').next().removeClass('active');
      $('.dropdownmenu-menu').removeClass('active');
    });

    if ($('.clickEvent').length) {
      const dropdown = $('.clickEvent');
      
      dropdown.on('click', function (e) {
        e.stopPropagation();
        if (!dropdown.next().hasClass('active')) {
          dropdown.next().addClass('active');
        } else {
          dropdown.next().removeClass('active');
        }
      });
      
      $('.custome-dropdownmenu').removeClass('active');
    }


  }

  openDropdown(){    
    this.notifyParent.emit();
  }
  
  clickEvent(){
    this.status = !this.status;
  }

  logout() {
    this.userService.signOut();
  }

}
