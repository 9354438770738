import { DocumentTypeDocumentMetadataRequestModel } from "./document-type-document-metadata-request";

export class DocumentTypeDocumentRequestModel {
    DocumentTypeDocumentId: number;
    CompanyId: number;
    DocumentTypeId: number;
    DocumentPath: string;
    IsPublic: boolean;
    LastModifiedBy: number;
    DateTimeCreated: Date;
    DateTimeLastModified: Date;
    
    DocumentTypeDocumentMetadatas: DocumentTypeDocumentMetadataRequestModel[];
}

export class DocumentTypeDocumentSearchRequestModel{
    DocumentTypeId: number;
    SearchText: string;
}