<div class="empty-state-outer not-found">
  <div class="container">
      <div class="empty-state-outer-block">
          <div class="empty-state-outer-block__box">
              <img src="assets/img/empty-state-img.svg" alt="empty-state" width="482" height="369">
              <br/>
              <h1>404</h1>
              <h3>Page Not found</h3>
              <p>Page does not exist or it has been changed.</p>
             
              <button class="btn-primary" type="submit" (click)="goToHome()">Go to Home 
                <svg width="21" height="13" viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8997 0.526477C13.9786 0.444857 14.0724 0.3801 14.1756 0.335916C14.2789 0.291732 14.3896 0.26899 14.5014 0.26899C14.6132 0.26899 14.7239 0.291732 14.8271 0.335916C14.9304 0.3801 15.0241 0.444857 15.1031 0.526477L20.2024 5.78514C20.2815 5.86655 20.3443 5.96327 20.3872 6.06975C20.43 6.17623 20.4521 6.29038 20.4521 6.40566C20.4521 6.52094 20.43 6.63509 20.3872 6.74157C20.3443 6.84805 20.2815 6.94477 20.2024 7.02618L15.1031 12.2848C14.9435 12.4494 14.7271 12.5419 14.5014 12.5419C14.2757 12.5419 14.0592 12.4494 13.8997 12.2848C13.7401 12.1203 13.6504 11.8971 13.6504 11.6643C13.6504 11.4316 13.7401 11.2084 13.8997 11.0438L18.3989 6.40566L13.8997 1.76752C13.8205 1.68611 13.7577 1.58939 13.7149 1.48291C13.672 1.37643 13.65 1.26228 13.65 1.147C13.65 1.03172 13.672 0.917566 13.7149 0.811087C13.7577 0.704608 13.8205 0.607891 13.8997 0.526477Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.0518265 6.40566C0.0518265 6.17322 0.141368 5.95029 0.300752 5.78592C0.460136 5.62156 0.676307 5.52922 0.901711 5.52922H18.7493C18.9747 5.52922 19.1909 5.62156 19.3502 5.78592C19.5096 5.95029 19.5992 6.17322 19.5992 6.40566C19.5992 6.63811 19.5096 6.86104 19.3502 7.0254C19.1909 7.18977 18.9747 7.28211 18.7493 7.28211H0.901711C0.676307 7.28211 0.460136 7.18977 0.300752 7.0254C0.141368 6.86104 0.0518265 6.63811 0.0518265 6.40566Z" fill="white"/>
                </svg>
                </button>
          </div>
      </div>
  </div>
</div>

