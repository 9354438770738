export class CommonFunctions {
  static enumSelector(definition): any {
    return Object.keys(definition)
      .map(key => ({ value: key, title: definition[key] }));
  }
  static stringEnumToKeyValue(stringEnum) {
    const keyValue = [];
    const keys = Object.keys(stringEnum)
    // .filter((value, index) => {
    //         return index;
    //         });

    for (const k of keys) {
      keyValue.push({ i: Number, key: k, value: stringEnum[k] });
    }
    return keyValue;
  }
}
