// export class MigrationClientRequestModel{
//     ClientId : number;
//     FirstName : string;
//     LastName : string;
//     Email : string
//     PhoneNo : string;
//     Password : string;
//     Role : string;
//     IsActive : boolean;
//     IsForgotPassword : boolean;
//     IsLinkExpired :boolean;
//     IsDeleted : boolean;
//     AllowedDocumentStorage : string;
//     SubscriptionStartDate : Date;
//     SubscriptionEndDate : Date;
//     DateTimeCreated : Date;
//     DateTimeLastModified : Date;
// }

export class MigrationClientRequestModel {
  ClientId: any;
  FirstName: any;
  LastName: any;
  Email: any;
  PhoneNo: any;
  Password: any;
  Role: any;
  IsActive: any;
  IsForgotPassword: any;
  IsLinkExpired: any;
  IsDeleted: any;
  AllowedDocumentStorage: any;
  SubscriptionStartDate: any;
  SubscriptionEndDate: any;
  DateTimeCreated: any;
  DateTimeLastModified: any;
  UpgradeStorage: any;
  UpgradeType: any;
  ModifiedBy: any;
}
