export class CabinetRequestModel{
  OrganizationName: string;
  ServerURL: string;
  UserName: string;
  Password: string;
  PassPhrase: string;
  CabinetId: string;
  MunicipalityId: number;
  CabinetMunicipalityId: number;
  DocuwareDocumentId: string;
  SearchFullText: string;

  PageNo: number;
  PageSize: number;

  DocuwareFieldData: DocuwareFieldData[];
}
export class DocuwareFieldData {
  FieldName: string;
  FieldValue: string;
}

export class DocuwareCabinetReuestModel {
  OrganizationName: string;
  ServerURL: string;
  UserName: string;
  Password: string;
  PassPhrase: string;
  CabinetId: string;
  CompanyId: number;
  DocumentCabinetId: number;
  DocuwareDocumentId: string;
  DocumentTypeId: number;
  LastModifiedBy: number;

  SearchFullText: string;
  PageNo: number;
  PageSize: number;
  
  SearchDialogId: string;
  DocuwareFieldData: DocuwareFieldData [];
}
