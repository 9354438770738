export class TestDocuwareConnectionModel {
  OrganizationName: string;
  ServerURL: string;
  UserName: string;
  Password: string;
}

// export class CabinetRequestModel {
//   OrganizationName: string;
//   ServerURL: string;
//   UserName: string;
//   Password: string;
//   PassPhrase: string;
//   CabinetId: string;
//   MunicipalityId: number;
// }
