import { Injectable } from '@angular/core';
import { Constants } from '../shared/constants';
import { UserAuthResponseModel } from '../models/response/access-token-response';

@Injectable({
  providedIn: 'root'
})
export class WebStorageService {

  constructor() { }


  getCurrentUserToken(): UserAuthResponseModel {
    return JSON.parse(this.getItem(Constants.currentUserToken));
  }

  storeCurrentUserToken(accessToken: UserAuthResponseModel) {
    this.setItem(Constants.currentUserToken, JSON.stringify(accessToken));
  }

  deleteCurrentUserToken() {
    this.removeItem(Constants.currentUserToken);
  }

  clear() {
    localStorage.clear();
  }

  private setItem(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  private removeItem(key: string) {
    localStorage.removeItem(key);
  }

  private getItem(key: string) {
    return localStorage.getItem(key);
  }
}
