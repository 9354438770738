import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { MigrationClientRequestModel } from 'src/app/models/request/migration-client-request';
import { MigrationClientResponseModel } from 'src/app/models/response/migration-client-response';
import { ResultOfT } from 'src/app/models/response/result';
import { MigrationClientService } from 'src/app/services/migration-client.service';
import { BaseComponent } from 'src/app/shared/base.component';
import { Constants } from 'src/app/shared/constants';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { enumMigrationClientRole } from 'src/app/shared/enum';
import { environment } from 'src/environments/environment';
import * as $ from "jquery";
import * as moment from 'moment';
import { UserAuthResponseModel } from '../../../models/response/access-token-response';
import { WebStorageService } from '../../../services/web-storage.service';

@Component({
  selector: 'app-manage-migration-client',
  templateUrl: './manage-migration-client.component.html',
  styleUrls: ['./manage-migration-client.component.sass']
})
export class ManageMigrationCLientComponent extends BaseComponent implements OnInit, OnDestroy {

  @Output() isClientListUpdated = new EventEmitter<boolean>();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Input('clientId') clientId: number;
  @Input('count') count: number;

  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();

  isEditMode: boolean = false;
  displayMessage: boolean;
  messageClass: boolean;
  message: any;
  isActive: boolean = true;

  clientResponseModel: MigrationClientResponseModel;
  manageClient: boolean = false;
  //roleList: any;
  subscriptionStartDate: Date;
  subscriptionEndDate: Date;
  storageLabel: string = ''
  StorageButton: string = 'Edit'
  isShowStorage: boolean = false
  validUpgradeStorage: string = '';
  currentUserToken: UserAuthResponseModel;
  constructor(private migrationClientService: MigrationClientService, private formBuilder: FormBuilder
    , private webStorageService: WebStorageService, private router: Router) {
    super();
    this.currentUserToken = this.webStorageService.getCurrentUserToken();
    if (this.clientId > 0) {
      this.getClientById(this.clientId);
    }
  }

  ngOnInit(): void {

    //this.roleList = Object.keys(enumMigrationClientRole).map(key => ({ label: enumMigrationClientRole[key], value: key }));
    //this.roleList = this.roleList.filter(where => where != enumMigrationClientRole.enumNone);


    this.buildForm();

    // $(function(){
    //   var appendthis =  ("<div class='modal-overlay js-modal-close'></div>");
    //     $('a[data-modal-id]').click(function(e) {
    //       e.preventDefault();
    //       $("body").append(appendthis);
    //       $(".modal-overlay").fadeTo(500, 0.7);
    //       var modalBox = $(this).attr('data-modal-id');
    //       $('#'+modalBox).fadeIn($(this).data());
    //     });


    //   $(window).resize(function() {
    //       $(".modal-box").css({
    //           top: ($(window).height() - $(".modal-box").outerHeight()) / 2,
    //           left: ($(window).width() - $(".modal-box").outerWidth()) / 2
    //       });
    //   });

    //   $(window).resize();

    //   });
  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.clientId > 0) {
      this.isEditMode = true;
      this.getClientById(this.clientId);
    } else {
      this.resetClientForm();
    }
  }

  formFields = {
    FirstName: 'FirstName',
    LastName: 'LastName',
    Email: 'Email',
    PhoneNo: 'PhoneNo',
    Password: 'Password',
   // Role: 'Role',
    IsActive: 'IsActive',
    AllowedDocumentStorage: 'AllowedDocumentStorage',
    SubscriptionStartDate: 'SubscriptionStartDate',
    SubscriptionEndDate: 'SubscriptionEndDate',
    UpgradeStorage: 'UpgradeStorage'
  }

  validationMessages = {
    FirstName: {
      required: 'First name is required',
      validCharacters: 'Enter valid First name'
    },
    LastName: {
      required: 'Last name is required',
      validCharacters: 'Enter valid Last name'
    },
    Email: {
      required: 'Email is required',
      email: 'Enter valid Email'
    },
    PhoneNo: {
      required: 'Phone Number is required',
      validNumber: 'Enter valid Phone',
      minlength: 'Phone number should be 10 digits'
    },
    AllowedDocumentStorage: {
      required: 'Storage is required',
      validNumber: 'Enter valid storage'
    },
    //Role: {
    //  required: 'Role is required'
    //},
    SubscriptionStartDate: {
      required: 'Subscription Start Date is required'
    },
    SubscriptionEndDate: {
      required: 'Subscription End Date is required',
      min: 'Subscription End Date cannot be before Subscription Start Date'
    },
    UpgradeStorage: {
      required: 'Storage is required',
      validNumber: 'Enter valid storage'
    }
  };

  private buildForm() {

    const formGroup = this.formBuilder.group({});

    formGroup.addControl(this.formFields.FirstName, this.formBuilder.control('',
      { validators: [Validators.required, CustomValidator.onlyCharacters], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.LastName, this.formBuilder.control('',
      { validators: [Validators.required, CustomValidator.onlyCharacters], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.Email, this.formBuilder.control('',
      { validators: [Validators.required, CustomValidator.email], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.PhoneNo, this.formBuilder.control('',
      { validators: [Validators.required, CustomValidator.phoneNumber], updateOn: Constants.formControlUpdateStrategy.change }));
    //formGroup.addControl(this.formFields.Role, this.formBuilder.control('',
    //  { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    // formGroup.addControl(this.formFields.IsActive, this.formBuilder.control(true,
    //   { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.AllowedDocumentStorage, this.formBuilder.control('',
      { validators: [Validators.required, CustomValidator.onlyDecimals], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.SubscriptionStartDate, this.formBuilder.control('',
      { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.SubscriptionEndDate, this.formBuilder.control('',
      { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.UpgradeStorage, this.formBuilder.control('',
      { validators: [CustomValidator.onlyDecimals] }));
    this.form = formGroup;
  }

  private getClientById(clientId: number) {
    // debugger;
    // console.log(clientId);
    if (!clientId) {
      return;
    }

    this.isEditMode = true;
    this.loading = true;

    this.migrationClientService.getMigrationClientInfo(clientId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<MigrationClientResponseModel>) => {
        this.processResult<MigrationClientResponseModel>(result, () => {
          if (result.IsSuccess) {
            this.clientResponseModel = result.ResponseModel;
            this.setValuesToForm();
            this.subscriptionEndDate = new Date(this.clientResponseModel.SubscriptionEndDate);
            this.subscriptionStartDate = new Date(this.clientResponseModel.SubscriptionStartDate);
            // console.log(result.ResponseModel)

          } else {
            this.timeoutFunct(result.Message, false);
          }
        });
      }, (httpResponseError) => {
        this.timeoutFunct(httpResponseError.message, false);
      });
  }

  setValuesToForm() {
    this.form.reset();

    if (!this.clientResponseModel) {
      return;
    }

    const form = this.form;

    form.get(this.formFields.FirstName).setValue(this.clientResponseModel.FirstName);
    form.get(this.formFields.LastName).setValue(this.clientResponseModel.LastName);
    form.get(this.formFields.Email).setValue(this.clientResponseModel.Email);
    form.get(this.formFields.PhoneNo).setValue(this.clientResponseModel.PhoneNo);
    //form.get(this.formFields.Role).setValue(this.clientResponseModel.Role);
    this.isActive = this.clientResponseModel.IsActive;
    form.get(this.formFields.AllowedDocumentStorage).setValue(this.clientResponseModel.AllowedDocumentStorage);
    form.get(this.formFields.SubscriptionEndDate).setValue(new Date(this.clientResponseModel.SubscriptionEndDate));
    form.get(this.formFields.SubscriptionStartDate).setValue(new Date(this.clientResponseModel.SubscriptionStartDate));
    this.clientResponseModel.UsedDocumentStorage = ((this.clientResponseModel.UsedDocumentStorage == null || this.clientResponseModel.UsedDocumentStorage == "")
      ? "0" : this.clientResponseModel.UsedDocumentStorage);
    //debugger;
    this.storageLabel = ''
    this.StorageButton = 'Edit'
    this.isShowStorage = false
    this.validUpgradeStorage = '';
    if (this.clientResponseModel.UsedDocumentStorage == "0") {
      this.StorageButton = "Edit Storage"
    }
    else {
      this.StorageButton = "Add Storage"
    }
    this.storageLabel = this.formatBytes(this.clientResponseModel.UsedDocumentStorage, 0) + ' of ' + this.clientResponseModel.AllowedDocumentStorage + ' GB'
  }
  ShowHideStorage() {
    this.isShowStorage = !this.isShowStorage;
  }
  resetClientForm() {
    this.manageClient = false;
    if (this.form) {
      this.form.reset();
    }
    this.isEditMode = false;
  }

  onDateSelect(event: any) {
    // debugger;
    this.subscriptionStartDate = event;

    if (this.subscriptionEndDate != null) {
      if (this.subscriptionStartDate > this.subscriptionEndDate) {
        this.form.get(this.formFields.SubscriptionEndDate).setValue('');
      }
    }
  }

  onSelectEndDate(event: any) {
    // debugger;
    this.subscriptionEndDate = event;
  }

  timeoutFunct(msg, isSuccess: boolean) {
    this.displayMessage = true;
    this.messageClass = isSuccess;
    this.message = msg;
    setTimeout(() => {
      this.message = '';
      this.displayMessage = false;
    }, environment.messageLifeSpan);
  }

  closeMessagePopup() {
    this.displayMessage = false;
  }

  closePopup() {
    this.close.emit();
    this.resetClientForm();
    $('.add-update-popup').fadeOut('fast');
  }

  closeDeletePopup() {
    this.form.reset();
    $('.add-update-popup').fadeOut('fast');
  }
  getValuesFromForm() {

    // debugger;
    const form = this.form;

    const clientRequestModel = new MigrationClientRequestModel();

    clientRequestModel.ClientId = this.isEditMode ? this.clientId : 0;
    clientRequestModel.FirstName = form.get(this.formFields.FirstName).value.toString().trim();
    clientRequestModel.LastName = form.get(this.formFields.LastName).value.toString().trim();
    clientRequestModel.Email = form.get(this.formFields.Email).value.toString()
    clientRequestModel.PhoneNo = form.get(this.formFields.PhoneNo).value
    //clientRequestModel.Role = form.get(this.formFields.Role).value
    clientRequestModel.AllowedDocumentStorage = form.get(this.formFields.AllowedDocumentStorage).value;
    clientRequestModel.SubscriptionStartDate = form.get(this.formFields.SubscriptionStartDate)?.value.toLocaleDateString();
    clientRequestModel.SubscriptionEndDate = form.get(this.formFields.SubscriptionEndDate)?.value.toLocaleDateString();
    clientRequestModel.IsActive = this.isActive;
    clientRequestModel.DateTimeCreated = new Date();
    clientRequestModel.DateTimeLastModified = new Date();
    clientRequestModel.IsForgotPassword = false;
    clientRequestModel.IsLinkExpired = false;
    clientRequestModel.IsDeleted = false;
    clientRequestModel.Password = " ";
    if (this.isEditMode && this.isShowStorage) {
      if (this.StorageButton == "Edit Storage")
        clientRequestModel.UpgradeType = "Edit";
      else
        clientRequestModel.UpgradeType = "Add";

      clientRequestModel.UpgradeStorage = form.get(this.formFields.UpgradeStorage).value;
    }
    else
      clientRequestModel.UpgradeStorage = "";

    clientRequestModel.ModifiedBy = this.currentUserToken.userId;



    return clientRequestModel;

  }
  onUpgradeStorage() {
    // debugger;
    this.validUpgradeStorage = '';
  }
  onSubmit() {
    this.validUpgradeStorage = '';
    if (this.form.invalid) {
      // const invalid = [];
      // const controls = this.form.controls;
      // for (const name in controls) {
      //     if (controls[name].invalid) {
      //         invalid.push(name);
      //     }
      // }
      // console.log(invalid);
      this.markFormAsTouched();
      return;
    }
    // debugger;
    if (this.isEditMode && this.isShowStorage) {
      if (this.form.get(this.formFields.UpgradeStorage).value == "" || this.form.get(this.formFields.UpgradeStorage).value == undefined) {
        this.validUpgradeStorage = this.validationMessages.UpgradeStorage.required
        return false;
      }
    }

    const clientRequestModel = this.getValuesFromForm();
    this.loading = true;
    const manageClient = this.isEditMode ? this.migrationClientService.updateMigrationClient(clientRequestModel)
      : this.migrationClientService.addMigrationClient(clientRequestModel);

    manageClient
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {

          if (result.IsSuccess) {

            if (result.ResponseModel) {
              var response = result.ResponseModel;
            }
            var message = this.isEditMode ? 'Client information updated' : 'Client information added';
            this.loading = false;
            this.timeoutFunct(message, true);
            setTimeout(() => {
              this.storageLabel = ''
              this.StorageButton = 'Edit'
              this.isShowStorage = false
              this.validUpgradeStorage = '';
              this.form.reset();
              this.closeDeletePopup();
              this.closePopup();
              this.isClientListUpdated.emit(true);
            }, environment.messageLifeSpan);

          } else {
            this.loading = false;
            this.timeoutFunct(result.Message, false);
          }
        });
      }, (httpResponseError) => {
        this.loading = false;
        this.timeoutFunct(httpResponseError.message, false);
      });

  }
  formatBytes(bytes: any, decimals = 2) {
    bytes = parseFloat(bytes);
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
}
