<div class="login-outer">
    <div class="login-outer__block">
        <div class="login-outer__block__box">
            <h1>{{title}}</h1>
            <h2>{{h2title}}</h2>
            <div class="alert" [ngClass]="messageClass ? 'success' : 'warning'" *ngIf="displayMessage">
              <p>{{message}}</p>
              <a class="close-popup" href="javascript:void(0);" (click)="closeMessagePopup()"><img width="20" src="assets/img/close-ic.svg"> </a>
            </div>

            <form [formGroup]="form" autocomplete="off">
                <div class="form__group">
                    <label class="form__label">Email</label>
                    <div class="login-inputuser">
                        <input type="text" class="form__control" formControlName="email">
                    </div>
                    <span *ngIf="hasError(formFields.email,'required')" class="error">{{validationMessages.email.required}}</span>
                    <span *ngIf="hasError(formFields.email,'email')" class="error">{{validationMessages.email.validemail}}</span>
                </div>
                <div class="form__group">
                    <label class="form__label">Password</label>
                    <div class="login-inputpass">
                        <input type="password" class="form__control" formControlName="password">
                    </div>
                    <span class="error" *ngIf="hasError(formFields.password,'required')">{{validationMessages.password.required}}</span>
                </div>
                <div class="form__group check-box">
                    <label class="checkbox-outer">
                        <input type="checkbox" checked="checked" formControlName="rememberMe">
                        <span class="checkmark"></span>
                    </label>
                    Remember Me
                </div>
                <div class="form__group mb-0 text-center">
                    <button class="btn-primary ml-0" type="submit" (click)="onSubmit()">Login</button>
                    <div class="forgot-password">
                        <a href="javascript:void(0);" routerLinkActive="active" routerLink="/forgot-password" >Forgot Password?</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
