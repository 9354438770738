import { ValidationErrors, AbstractControl, ValidatorFn, FormGroup } from '@angular/forms';

export class CustomValidator {

  static whitespace(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const controlValue = control.value;
      if (controlValue.toString().trim().length > 0)
        return null;

      return { whitespace: true };
    }
    else
      return null;
  }

  static onlyCharacters(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const TEXT_REGEXP = /^[a-zA-Z\s]+$/;
      if (TEXT_REGEXP.test(control.value))
        return null;

      return { validCharacters: true };
    }
    else
      return null;
  }

  static userNameDocuware(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const TEXT_REGEXP = /^[a-zA-Z.@\d\s]*$/;
      if (TEXT_REGEXP.test(control.value))
        return null;

      return { validCharacters: true };
    }
    else
      return null;
  }

  static URL(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const PHONE_REGEXP = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
      if (PHONE_REGEXP.test(control.value))
        return null;

      return { validURL: true };
    }
    else
      return null;
  }

  static passwordPattern(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const TEXT_REGEXP = /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
      if (TEXT_REGEXP.test(control.value))
        return null;

      return { validStrongPassword: true };
    }
    else
      return null;
  }

  static email(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const TEXT_REGEXP = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      if (TEXT_REGEXP.test(control.value))
        return null;

      return { email: true };
    }
    else
      return null;
  }

  static passwordMatch(passwordFieldId: string, confirmPasswordFieldId: string): ValidatorFn {
    return (control: FormGroup): { [key: string]: boolean } | null => {
      var passwordField = control.controls[passwordFieldId];
      var confirmPasswordField = control.controls[confirmPasswordFieldId]
      if (passwordField == null || confirmPasswordField == null)
        return null;

      const password: string = passwordField.value;
      const confirmPassword: string = confirmPasswordField.value;

      if (password && confirmPassword) {
        if (password == confirmPassword)
          return null;

        return { validPasswordMatch: true };
      }
      else
        return null;
    };
  }

  static onlyNumbers(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const NUMBER_REGEXP = /^-?[\d.]+(?:e-?\d+)?$/;
      if (NUMBER_REGEXP.test(control.value))
        return null;

      return { validNumber: true };
    }
    else
      return null;
  }

  static onlyDecimals(control: AbstractControl): ValidationErrors | null {
    if (control.value) {      
      const NUMBER_REGEXP = /^[1-9]\d*(\.\d+)?$/;
      if (NUMBER_REGEXP.test(control.value))
        return null;

      return { validNumber: true };
    }
    else
      return null;
  }

  static alphaNumericSpace(control: AbstractControl): ValidationErrors | null {
    if (control.value) {      
      const NUMBER_REGEXP = /^[\w\-\s]+$/;
      if (NUMBER_REGEXP.test(control.value))
        return null;

      return { validCharacters: true };
    }
    else
      return null;
  }
  static phoneNumber(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const NUMBER_REGEXP = /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/;
      if (NUMBER_REGEXP.test(control.value))
        return null;

      return { validNumber: true };
    }
    else
      return null;
  }
}
