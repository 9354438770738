import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResultOfT, Result } from '../models/response/result';
import { MunicipalityResponseModel } from '../models/response/municipality-response';
import { TestDocuwareConnectionModel } from '../models/request/test-docuware-connection-model';
import { MunicipalityRequestModel } from '../models/request/municipality-request';
import { CabinetMunicipalityMappingResponseModel } from '../models/response/cabinet-municipality-mapping-response';
import { DownloadFile, ForgotPasswordModel, ResetPasswordModel } from '../models/response/forgot-password';
import { CustomAuthResponseModel } from '../models/response/custom-auth';
import { CabinetRequestModel } from '../models/request/cabinet-request';
import { GroupResponseModel } from '../models/response/group-response';
import { GroupRequestModel } from '../models/request/group-request';
import { DocumentCabinetDisplayResponseModel, DocumentCabinetListResponseModel, DocumentCabinetResponseModel } from '../models/response/document-cabinet-response';
import { PermissionResponseModel } from '../models/response/permission-response';
import { DocumentTypeListResponseModel, DocumentTypePermissionsResponseModel, DocumentTypeResponseModel, DocumentTypeUserGroupResponseModel, DocumentTypeWithDocNumberResponseModel, SPDocumentTypesByCompanyIdResponseModel } from '../models/response/document-type-response';
import { DocumentPublicRequestModel, DocumentTypeRequestModel, PermissionByDocTypeRequestModel, UploadFileRequestModel } from '../models/request/document-type-request';
import { DocumentCabinetRequestModel } from '../models/request/document-cabinet-request';
import { DocumentTypeDocumentModelForSearch, DocumentTypeDocumentResponseModel, LabelValueModel, SPDocumentsByDocType, SPFullTextModel } from '../models/response/document-type-document-response';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DocumentTypeInboxResponseModel } from '../models/response/document-type-inbox-response';
import { MetadataSearchRequestModel } from '../models/request/metadata-search-request';
import { SearchableFieldValueResponseModel } from '../models/response/searchable-field-value-response';
import { DocumentTypeDocumentSearchRequestModel } from '../models/request/document-type-document-request';

@Injectable({
  providedIn: 'root'
})
export class DocumentCabinetService extends BaseService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  BindDocumentCabinetDropdown(companyId: number) {
    return this.get<ResultOfT<DocumentCabinetResponseModel[]>>('DocumentCabinet/BindDropdownDocumentCabinets/' + companyId);
  }

  BindDocumentTypeDropdown(documentCabinetId: number) {
    return this.get<ResultOfT<DocumentTypeResponseModel[]>>('DocumentCabinet/BindDropdownDocumentTypes/' + documentCabinetId);
  }

  GetAllDocumentCabinet(companyId: number) {
    return this.get<ResultOfT<DocumentCabinetResponseModel[]>>('DocumentCabinet/GetAllDocumentCabinets/' + companyId);
  }

  GetAllDocumentCabinetList(companyId: number) {
    return this.get<ResultOfT<DocumentCabinetListResponseModel[]>>('DocumentCabinet/GetAllDocumentCabinetList/' + companyId);
  }

  GetAllPermission() {
    return this.get<ResultOfT<PermissionResponseModel[]>>('Permission/GetAll');
  }

  GetDocumentCabinetById(documentCabinetId:number){
    return this.get<ResultOfT<DocumentCabinetResponseModel>>('DocumentCabinet/GetDocumentCabinetById/' + documentCabinetId);
  }

  AddDocumentCabinet(reqModel: DocumentCabinetRequestModel) {
    return this.post<ResultOfT<DocumentCabinetResponseModel>>('DocumentCabinet/AddDocumentCabinet', reqModel);
  }

  UpdateDocumentCabinet(reqModel: DocumentCabinetRequestModel) {
    return this.post<ResultOfT<DocumentCabinetResponseModel>>('DocumentCabinet/UpdateDocumentCabinet', reqModel);
  }

  DeactiveDocumentCabinet(documentCabinetId: number){
    return this.get<ResultOfT<boolean>>('DocumentCabinet/DeactiveDocumentCabinet' + documentCabinetId);
  }


  GetAllDocumentTypes(companyId: number) {
    return this.get<ResultOfT<DocumentTypeResponseModel[]>>('DocumentCabinet/GetAllDocumentTypes/' + companyId);
  }

  GetAllDocumentTypeList(documentCabinetId: number) {
    return this.get<ResultOfT<DocumentTypeResponseModel[]>>('DocumentCabinet/GetAllDocumentTypeList/' + documentCabinetId);
  }

  GetDocumentTypeId(documentTypeId: number){
    return this.get<ResultOfT<DocumentTypeResponseModel>>('DocumentCabinet/GetDocumentTypeById/' + documentTypeId);
  }

  AddDocumentType(reqModel: DocumentTypeRequestModel) {
    return this.post<ResultOfT<DocumentTypeResponseModel>>('DocumentCabinet/AddDocumentType', reqModel);
  }

  UpdateDocumentType(reqModel: DocumentTypeRequestModel) {
    return this.post<ResultOfT<DocumentTypeResponseModel>>('DocumentCabinet/UpdateDocumentType', reqModel);
  }

  UpdateDocumentTypeForUrlVisibility(reqModel: DocumentTypeRequestModel){    
    return this.post<ResultOfT<DocumentTypeResponseModel>>('DocumentCabinet/UpdateDocumentTypeForUrlVisibility', reqModel);
  }

  UpdateDocumentTypeForPublic(reqModel: DocumentPublicRequestModel) {
    return this.post<ResultOfT<boolean>>('DocumentCabinet/UpdateDocumentTypeForPublic', reqModel);
  }
  
  UpdateCustomizePageDesign(reqModel: DocumentTypeRequestModel) {
    return this.post<ResultOfT<DocumentTypeResponseModel>>('DocumentCabinet/UpdateCustomizePageDesign', reqModel);
  }

  GetAllDocumentTypeDocument(documentTypeId: number) {
    return this.get<ResultOfT<DocumentTypeDocumentResponseModel[]>>('DocumentCabinet/GetAllDocumentTypeDocument/' + documentTypeId);
  }

  GetDocumentTypeDocument(documentTypeDocumentId: number) {
    return this.get<ResultOfT<DocumentTypeDocumentResponseModel>>('DocumentCabinet/GetDocumentTypeDocumentById/' + documentTypeDocumentId);
  }

  GetDocumentById(documentId: number) {
    return this.get<ResultOfT<DocumentTypeInboxResponseModel>>('DocumentCabinet/GetDocumentById/' + documentId);
  }

  DeleteDocumentTypeDocument(documentTypeDocument: number){
    return this.get<ResultOfT<boolean>>('DocumentCabinet/DeleteDocumentTypeDocument/' + documentTypeDocument);
  }

  DeleteDocumentById(documentTypeInboxId: number){
    return this.get<ResultOfT<boolean>>('DocumentCabinet/DeleteDocumentById/' + documentTypeInboxId);
  }

  ShouldDeleteDocumentTypeMetadata( documentTypeMetadtaId :string ) {
    return this.get<ResultOfT<boolean>>('DocumentCabinet/ShouldDeleteDocumentTypeMetadata/' + documentTypeMetadtaId);
  }

  DeleteDocumentTypeMetadata( documentTypeMetadtaId :string){
    return this.get<ResultOfT<boolean>>('DocumentCabinet/DeleteDocumentTypeMetadata/' + documentTypeMetadtaId);
  }

  UploadAddDocumentTypeDocument(data: FormData) {
    return this.post<ResultOfT<boolean>>('DocumentCabinet/UploadAddDocumentTypeDocument', data);
  }

  UploadUpdateDocumentTypeDocument(data: FormData) {
    return this.post<ResultOfT<boolean>>('DocumentCabinet/UploadUpdateDocumentTypeDocument', data);
  }

  GetAllInboxRecordsByTypeId(documentTypeId: number) {
    return this.get<ResultOfT<DocumentTypeInboxResponseModel[]>>('DocumentCabinet/GetAllInboxRecordsByTypeId/' + documentTypeId)
  }

  DeleteDocumentTypeInboxRecordById(documentTypeInboxId: number) {
    return this.get<ResultOfT<boolean>>('DocumentCabinet/DeleteDocumentTypeInboxRecordById/' + documentTypeInboxId)
  }

  UploadDocumentsInInbox(data: FormData) {
    return this.post<ResultOfT<DocumentTypeInboxResponseModel[]>>('DocumentCabinet/UploadDocumentsInInbox', data);
  }

  AddDocumentRecordFromInbox(reqModel: UploadFileRequestModel) {
    return this.post<ResultOfT<boolean>>('DocumentCabinet/AddDocumentRecordFromInbox', reqModel);
  }

  UpdateDocumentRecordFromInbox(reqModel: UploadFileRequestModel) {
    return this.post<ResultOfT<boolean>>('DocumentCabinet/UpdateDocumentRecordFromInbox', reqModel);
  }
  
  DownloadFile(reqModel: DownloadFile) {
    return this.downloadBlobFile('DocumentCabinet/DownloadFile', reqModel);
    // return this.get('DocumentCabinet/DownloadFile/' + fileName);
    // return this.post('DocumentCabinet/DownloadFile', { StrFileName: fileName } );
  }
  DownloadFileNew(reqModel: DownloadFile) {
    return this.downloadBlobFile('DocumentCabinet/DownloadFileNew', reqModel);
    // return this.get('DocumentCabinet/DownloadFile/' + fileName);
    // return this.post('DocumentCabinet/DownloadFile', { StrFileName: fileName } );
  }
  GetBase64ForViewer(reqModel: DownloadFile) {
    return this.post<ResultOfT<string>>('DocumentCabinet/GetBase64ForViewer', reqModel);
  }
  GetBase64ForViewerNew(reqModel: DownloadFile) {
    return this.post<ResultOfT<string>>('DocumentCabinet/GetBase64ForViewerNew', reqModel);
  }

  //Start: New Flow
  GetAllDocumentTypesByCabinetIdWithDocCount(cabinetId: number) {
    return this.get<ResultOfT<DocumentTypeWithDocNumberResponseModel[]>>('DocumentCabinet/GetAllDocumentTypesByCabinetIdWithDocCount/' + cabinetId);
  }
  GetAllDocumentTypesByCabinetIdWithDocCountForCustomer(reqModel: PermissionByDocTypeRequestModel) {
    return this.post<ResultOfT<DocumentTypeWithDocNumberResponseModel[]>>('DocumentCabinet/GetAllDocumentTypesByCabinetIdWithDocCountForCustomer' , reqModel);
  }
  GetAllDocumentTypesByCabinetId(cabinetId: number) {
    return this.get<ResultOfT<DocumentTypeResponseModel[]>>('DocumentCabinet/GetAllDocumentTypesByCabinetId/' + cabinetId);
  }

  GetAllDocumentTypesByCompanyId(companyId: number) {
    return this.get<ResultOfT<SPDocumentTypesByCompanyIdResponseModel[]>>('DocumentCabinet/DocumentTypesByCompanyId/' + companyId);
  }
  DeleteDocumentCabinet(documentCabinetId: number){
    return this.get<ResultOfT<boolean>>('DocumentCabinet/DeleteDocumentCabinet/' + documentCabinetId);
  }

  DeleteDocumentType(documentTypeId: number){
    return this.get<ResultOfT<boolean>>('DocumentCabinet/DeleteDocumentType/' + documentTypeId);
  }

  UpdateDocumentTypeDocumentsForPublicByDocType(reqModel: DocumentPublicRequestModel) {
    return this.post<ResultOfT<boolean>>('DocumentCabinet/UpdateDocumentsForPublicByDocType', reqModel);
  }

  GetDocumentTypeUserGroupsByCompany(companyId: number) {
    return this.get<ResultOfT<DocumentTypeUserGroupResponseModel[]>>('DocumentCabinet/GetDocumentTypeUserGroupsByCompany/' + companyId);
  }

  GetAllDocumentTypePermissions(documentTypeId: number) {
    return this.get<ResultOfT<DocumentTypePermissionsResponseModel>>('DocumentCabinet/GetAllDocumentTypePermissions/' + documentTypeId)
  }

  GetCabinetsForInboxModule(companyId: number) {
    return this.get<ResultOfT<DocumentCabinetDisplayResponseModel[]>>('DocumentCabinet/GetCabinetsForInboxModule/' + companyId);
  }
  GetCabinetsForSearchModule(companyId: number) {
    return this.get<ResultOfT<DocumentCabinetDisplayResponseModel[]>>('DocumentCabinet/GetCabinetsForSearchModule/' + companyId);
  }

  GetCabinetsForInboxModuleForCustomer(userId: number) {
    return this.get<ResultOfT<DocumentCabinetDisplayResponseModel[]>>('DocumentCabinet/GetCabinetsForInboxModuleForCustomer/' + userId);
  }
  
  GetCabinetsForSearchModuleForCustomer(userId: number) {
    return this.get<ResultOfT<DocumentCabinetDisplayResponseModel[]>>('DocumentCabinet/GetCabinetsForSearchModuleForCustomer/' + userId);
  }

  GetAllDocumentsByDocType(documentTypeId: number){
    return this.get<ResultOfT<SPDocumentsByDocType[]>>('DocumentCabinet/GetAllDocumentsByDocType/' + documentTypeId);
  }

  GetInboxRecordsById(documentTypeInboxId: number){
    return this.get<ResultOfT<DocumentTypeInboxResponseModel>>('DocumentCabinet/GetInboxRecordsById/' + documentTypeInboxId);
  }

  GetAllDocumentTypesListForCustomer(reqModel: PermissionByDocTypeRequestModel) {
    return this.post<ResultOfT<DocumentTypeListResponseModel[]>>('DocumentCabinet/GetAllDocumentTypesListForCustomer' , reqModel);
  }

  GetRawDataForDynamicObject(documentTypeId: number, forCustom: boolean){
    return this.get<ResultOfT<DocumentTypeDocumentModelForSearch>>('DocumentCabinet/GetRawDataForDynamicObject/' + documentTypeId + '/' + forCustom);
  }

  GetFilterData(documentTypeMetadataId: number) {
    return this.get<ResultOfT<LabelValueModel[]>>('DocumentCabinet/GetFilterData/' + documentTypeMetadataId);
  }

  GetFilterDataForSearch(documentTypeMetadataId: number, searchText: string) {
    return this.get<ResultOfT<LabelValueModel[]>>('DocumentCabinet/GetFilterDataForSearch/' + documentTypeMetadataId + '/' + searchText);
  }

  GetFilterDataForSpecialCompanies(searchableFieldId: number, searchText: string) {
    return this.get<ResultOfT<SearchableFieldValueResponseModel[]>>('DocumentCabinet/GetFilterDataForSpecialCompanies/' + searchableFieldId + '/' + searchText);
  }

  // GetDocumentTypeDocumentIdsForFullText(documentTypeId: number, searchText: string ){
  //   return this.get<ResultOfT<SPFullTextModel[]>>('DocumentCabinet/GetDocumentTypeDocumentIdsForFullText/' + documentTypeId + '/' + searchText);
  // }
  GetDocumentTypeDocumentIdsForFullText(reqModel: DocumentTypeDocumentSearchRequestModel){
    return this.post<ResultOfT<SPFullTextModel[]>>('DocumentCabinet/GetDocumentTypeDocumentIdsForFullText/' , reqModel);
  }
  
  GetMetadataSearchData(reqModel: MetadataSearchRequestModel ){
    return this.post<ResultOfT<DocumentTypeDocumentModelForSearch>>('DocumentCabinet/GetMetadataSearchData' , reqModel);
  }

  UpdateDocumentTypeDocumentMetadata(reqModel: FormData) {
    return this.post<ResultOfT<boolean>>('DocumentCabinet/UpdateDocumentTypeDocumentMetadata', reqModel);
  }


  //End

  ////test start
  //TestDynamicObject(documentTypeId: number) {
  //  return this.get<ResultOfT<DocumentTypeDocumentModelForSearch>>('DocumentCabinet/TestDynamicObject/' + documentTypeId);
  //}

  //TestFetchFilterData(documentTypeMetadataId: number, searchText: string) {
  //  return this.get<ResultOfT<LabelValueModel[]>>('DocumentCabinet/TestFetchFilterData/' + documentTypeMetadataId + '/' + searchText);
  //}
  ////test end


}
