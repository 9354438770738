import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  /**
 * Get
 * @param url
 */
  //protected get<T>(url: string): Observable<T> {
  //  //let headers = new HttpHeaders();
  //  //headers.append('companyCode', 'cmInception');
  //  return this.httpClient.get<T>(this.getApiUrl(url), { headers: new HttpHeaders().set('companyCode', 'cmInception') });
  //}
  protected getDownloadBlobFile(url: string, httpHeaders: HttpHeaders = null): Observable<Blob> {
    //return this.httpClient.request(new HttpRequest(
    //  'GET',
    //  this.getApiUrl(url),
    //  null,
    //  {
    //    reportProgress: true,
    //    responseType: 'blob'

    //  }));
    return this.httpClient.get(this.getApiUrl(url),
      {
        headers: httpHeaders,
        responseType: 'blob'

      })
  }

  protected get<T>(url: string, httpHeaders: HttpHeaders = null): Observable<T> {
    return this.httpClient.get<T>(this.getApiUrl(url),
      {
        // headers: httpHeaders
        headers: new HttpHeaders({ 'Content-Type':  'application/json',
        'Access-Control-Allow-Credentials' : 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',})
      });
  }

  protected getConfig<T>(url: string): Observable<T> {

    return this.httpClient.get<T>(this.getConfigUrl(url));
  }

  /**
   * Post
   * @param url
   * @param body
   * @param httpHeaders
   */
  //protected post<T>(url: string, body: any, httpHeaders: HttpHeaders = new HttpHeaders().set('companyCode', 'cmInception')): Observable<T> {
  protected post<T>(url: string, body: any, httpHeaders: HttpHeaders = null): Observable<T> {
    return this.httpClient.post<T>(this.getApiUrl(url), body,
      {
        headers: httpHeaders
      });
  }

  protected postUpload<T>(url: string, body: any, httpHeaders: HttpHeaders = null): Observable<T> {
    // debugger
    const httpOptions = {
      headers: new HttpHeaders({ 'Accept': 'application/json' ,'Content-Type': 'application/json' })
    }
    return this.httpClient.post<T>(this.getApiUrl(url), body,
      httpOptions);
  }


  protected postconfig<T>(url: string, body: any, httpHeaders: HttpHeaders = null): Observable<T> {
    return this.httpClient.post<T>(this.getConfigUrl(url), body,
      {
        headers: httpHeaders
      });
  }

  protected downloadFile(url: string, httpHeaders: HttpHeaders = null) : Observable<any> {
    return this.httpClient.get(this.getApiUrl(url),
    {
      headers: new HttpHeaders({ 'Content-Type':  'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Credentials' : 'true',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
      'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'}),
      responseType: 'blob'
    });
  }

  protected downloadBlobFile(url: string, body: any, httpHeaders: HttpHeaders = null): Observable<Blob> {
    return this.httpClient.post(this.getApiUrl(url), body,
      {
        headers: httpHeaders,
        responseType: 'blob'
      });
  }

  protected downloadTextFile(url: string, body: any, httpHeaders: HttpHeaders = null): Observable<string> {
    return this.httpClient.post(this.getApiUrl(url), body,
      {
        headers: httpHeaders,
        responseType: 'text'
      });
  }

  protected downloadCsvFile(url: string, body: any = null, httpHeaders: HttpHeaders = null): Observable<Blob> {
    return this.httpClient.post(this.getApiUrl(url), body,
      {
        headers: httpHeaders,
        responseType: 'blob'
      });
  }

  // protected postUpload<T>(url: string, body: any, httpHeaders: HttpHeaders = null): Observable<T> {
  //   return this.httpClient.post<T>(this.getApiUrl(url), body,
  //     {
  //       headers: new HttpHeaders({ 'Content-Type':  'application/json',
  //       'Access-Control-Allow-Credentials' : 'true',
  //       'Access-Control-Allow-Origin': '*',
  //       'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
  //       'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',})
  //     });
  // }

  private getApiUrl(url: string): string {
    return environment.apiBaseUrl + url;
  }

  private getConfigUrl(url: string): string {
    return environment.baseUrl + url;
  }
}
