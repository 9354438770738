import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../components/header/header.component';
import { DropdownMenuComponent } from '../components/dropdown-menu/dropdown-menu.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { HeaderCustomerComponent } from '../components/header-customer/header-customer.component';
import { DragAndDropDirective } from '../directive/drag-and-drop.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ManageDocuwareCabinetComponent } from '../document-cabinet/manage-docuware-cabinet/manage-docuware-cabinet.component';
import { DocumentMappingComponent } from '../new-workflow-design/inbox/uploaded-documents/document-mapping/document-mapping.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { DialogModule } from 'primeng/dialog';
//import { ImgPdfViewerModule } from 'img-pdf-viewer';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxPrintModule } from 'ngx-print';


@NgModule({
  declarations: [HeaderComponent, 
    DropdownMenuComponent, 
    HeaderCustomerComponent, 
    DragAndDropDirective, 
    ManageDocuwareCabinetComponent,
    DocumentMappingComponent,
  ],
  imports: [    
    ReactiveFormsModule,
    CommonModule,
    DropdownModule,
    FormsModule,
    RouterModule,
    OverlayPanelModule,
    TableModule,
    CalendarModule,
    DragDropModule,
    InputNumberModule,
    DialogModule,
    //ImgPdfViewerModule,
    PdfViewerModule,
    NgxPrintModule
  ],
  exports: [
    HeaderComponent, 
    DropdownMenuComponent, 
    CalendarModule, 
    TableModule, 
    HeaderCustomerComponent, 
    DragAndDropDirective,
    DragDropModule,
    ManageDocuwareCabinetComponent,
    DocumentMappingComponent,
    DialogModule,
    //ImgPdfViewerModule,
    PdfViewerModule,
    NgxPrintModule
  ]
})
export class SharedModule { }
