export enum UserRole {
  enumAdmin = 'InceptionAdmin',
  enumCustomerAdmin = 'CustomerAdmin',
  enumCustomer = 'Customer'
}

export enum enumPermission {
  enumFullAccess = "Full Access",
  enumEdit = "Edit",
  enumCreate = "Create",
  enumView = "View",
  enumDelete = "Delete"
}

export enum enumDatabaseType {
  enumInApp = "App Database",
  enumDocuware = "Docuware"
}

export enum enumCompanyStatus {
  enumNone = "Select Status",
  enumActive = "Active",
  enumInactive = "Inactive"
  // enumActive = "Active Accounts",
  // enumInactive = "Inactive Accounts"
}

export enum enumPermissionMappingWithId {  
  enumCreate = '1',
  enumEdit = '2',
  enumFullAccess = '3',
  enumView = '4',
  enumDelete = '6',
}

export enum enumFieldTypes {
  enumText255 = 'Text(255)',
  enumText2k = 'Text(2000)',
  enumInteger = 'Integer',
  enumDecimal = 'Decimal',
  enumDate = 'Date',
  enumMemo = 'Memo',
  enumList = "List",
  enumCurrency = 'Currency',
  enumBoolean = 'Boolean'
}
export enum enumMigrationClientStatus {
  enumNone = "Select Status",
  enumActive = "Active",
  enumInactive = "Inactive"
}

export enum enumMigrationClientRole {
  enumNone = "Select Role",
  enumAdmin = "Admin",
  enumClient = "Client"
}
