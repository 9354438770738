import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthenticationGuard } from './guard/authentication.guard';
import { GroupComponent } from './customer/group/group.component';
import { GroupManageComponent } from './customer/group/group-manage/group-manage.component';
import { CompanyComponent } from './inception/company/company.component';
import { CompanyManageComponent } from './inception/company/company-manage/company-manage.component';
import { DocumentCabinetComponent } from './document-cabinet/document-cabinet.component';
import { DocumentThemeSetupComponent } from './document-cabinet/document-theme-setup/document-theme-setup.component';
import { ManageDocumentCabinetComponent } from './document-cabinet/manage-document-cabinet/manage-document-cabinet.component';
import { UserComponent } from './customer/user/user.component';
import { UserManageComponent } from './customer/user/user-manage/user-manage.component';
import { PermissionComponent } from './customer/permission/permission.component';
import { ManageDocuwareCabinetComponent } from './document-cabinet/manage-docuware-cabinet/manage-docuware-cabinet.component';
import { DocumentTypeDocumentComponent } from './document-cabinet/document-type-document/document-type-document.component';
import { ManageDocumentTypeDocumentComponent } from './document-cabinet/manage-document-type-document/manage-document-type-document.component';
import { InceptionCustomWebPageComponent } from './inception-custom-web-page/inception-custom-web-page.component';
import { DocumentInboxComponent } from './document-cabinet/document-inbox/document-inbox.component';
import { TileDocumentCabinetComponent } from './document-cabinet/tile-document-cabinet/tile-document-cabinet.component';
import { TileDocumentTypeComponent } from './document-cabinet/tile-document-type/tile-document-type.component';
import { MigrationCLientComponent } from './new-workflow-design/migration-client/migration-client.component';
import { ResetMigrationClientPasswordComponent } from './reset-migration-client-password/reset-migration-client-password.component';
import { ForgotMigrationClientPasswordComponent } from './forgot-migration-client-password/forgot-migration-client-password.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  // {
  //   path: 'municipality-accounts',
  //   canActivate: [AuthenticationGuard],
  //   component: MunicipalityAccountsComponent
  // },
  // {
  //   path: 'municipality-account/add',
  //   canActivate: [AuthenticationGuard],
  //   component: ManageComponent
  // },
  // {
  //   path: 'municipality-account/edit/:id',
  //   canActivate: [AuthenticationGuard],
  //   component: ManageComponent
  // },
  // {
  //   path: 'municipality-account/add-customer',
  //   canActivate: [AuthenticationGuard],
  //   component: ManageCustomerComponent
  // },
  // {
  //   path: 'manage-cabinet/:id',
  //   canActivate: [AuthenticationGuard],
  //   component: ManageCabinetsComponent
  // },
  {
    path: 'reset-password/:queryString',
    component: ResetPasswordComponent
  },
  {
    path: 'my-profile',
    canActivate: [AuthenticationGuard],
    component: MyProfileComponent
  },
  {
    path: 'change-password',
    canActivate: [AuthenticationGuard],
    component: ChangePasswordComponent
  },
  {
    path: 'custom-webpage/:queryString',
    component: InceptionCustomWebPageComponent
  },
  {
    path: 'docuware-migration-subscription',
    component: MigrationCLientComponent
  },
  {
    path: 'reset-client-password/:queryString',
    component: ResetMigrationClientPasswordComponent
  },
  {
    path: 'forgot-client-password',
    component: ForgotMigrationClientPasswordComponent
  },
  {
    path: 'company',
    canActivate: [AuthenticationGuard],
    children: [
      { path : '' , component: CompanyComponent},
      // {
      //   path: 'add',
      //   component: CompanyManageComponent
      // },
      // {
      //   path: ':id/document-cabinet',
      //   component: TileDocumentCabinetComponent
      // },
      // {
      //   // path: ':id/document-cabinet/:documentcabinetid/document-type',
      //   path: ':id/document-cabinet-old',
      //   component: DocumentCabinetComponent
      // },
      // {
      //   path: ':id/document-cabinet/:documentcabinetid/document-type',
      //   component: TileDocumentTypeComponent
      // },
      // {
      //   path: ':id/document-cabinet/add',
      //   component: ManageDocumentCabinetComponent
      // },
      // {
      //   path: ':id/document-cabinet/edit/:documenttypeid',
      //   component: ManageDocumentCabinetComponent
      // },
      // {
      //   path: ':id/document-cabinet/:documentcabinetid/document-type/:documenttypeid/documents',
      //   component: DocumentTypeDocumentComponent
      // },
      // {
      //   path: ':id/document-cabinet/:documentcabinetid/document-type/:documenttypeid/document-inbox',
      //   component: DocumentInboxComponent
      // },
      // {
      //   path: ':id/docuware-cabinet/add',
      //   component: ManageDocuwareCabinetComponent
      // },
      // {
      //   path: ':id/docuware-cabinet/edit/:documentcabinetid',
      //   component: ManageDocuwareCabinetComponent
      // },
      // {
      //   path: ':id/groups',
      //   //canActivate: [AuthenticationGuard],
      //   component: GroupComponent
      // },
      // {
      //   path: ':id/users',
      //   component: UserComponent
      // },
      // New workflow design routes
      {
        path: ':id/inbox',
        loadChildren: () => import('./new-workflow-design/inbox/inbox.module').then(m => m.InboxModule)
      },
      {
        path: ':id/search',
        loadChildren: () => import('./new-workflow-design/search/search.module').then(m => m.SearchModule)
      },
      {
        path: ':id/configuration',
        loadChildren: () => import('./new-workflow-design/configuration/configuration.module').then(m => m.ConfigurationModule),
      },
      {
        path: ':id/configuration/document-filing-cabinets',
        loadChildren: () => import('./new-workflow-design/configuration/document-filling-cabinets/document-filling-cabinets.module').then(m => m.DocumentFillingCabinetsModule)
      },
      {
        path: ':id/configuration/user-administration',
        loadChildren: () => import('./new-workflow-design/configuration/user-administration/user-administration.module').then(m => m.UserAdministrationModule)
      },
      {
        path: ':id/configuration/public-access-settings',
        loadChildren: () => import('./new-workflow-design/configuration/public-access-settings/public-access-settings.module').then(m => m.PublicAccessSettingsModule)
      },
      {
        path: ':id/configuration/partner-api-settings',
        loadChildren: () => import('./new-workflow-design/configuration/partner-api-settings/partner-api-settings.module').then(m => m.PartnerApiSettingsModule)
      }
    ]
  },
  {
    path: '404',
    component: PageNotFoundComponent,
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
