export class ForgotPasswordModel {
  email: string;
  userId: number;
  encryptedUserId: string;
  encryptedDate: string;
  encryptedString: string;
  password: string;
}

export class ResetPasswordModel {
  userId: number;
  email: string;
  password: string;
}

export class ChangePasswordModel {
  userId: number;
  oldPassword: string;
  newPassword: string;
}

export class DownloadFile {
  StrFileName: string;
  StrCompanyId: string;
  StrDocumentTypeId: string;
  DownloadFromInbox: boolean;
  StrDocumentTypeInboxId: string;
}
