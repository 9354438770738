import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyRequestModel } from '../models/request/company-request';
import { TestDocuwareConnectionModel } from '../models/request/test-docuware-connection-model';
import { CompanyResponseModel } from '../models/response/company-response';
import { ResultOfT } from '../models/response/result';
import { ClientCredentialsModel } from '../models/response/userInfo-response';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends BaseService{

  constructor(httpService: HttpClient, private router: Router) {
    super(httpService);
  }

  getAllCompanies() {
    return this.get<ResultOfT<CompanyResponseModel[]>>('Company/GetAllCompanies');
  }

  getCompanyById(companyId: number) {
    return this.get<ResultOfT<CompanyResponseModel>>('Company/GetCompanyById/' + companyId);
  }

  getStorageInfoByCompanyId(companyId: number) {
    return this.get<ResultOfT<string>>('Company/GetStorageByCompanyId/' + companyId);
  }

  addCompany(reqModel: CompanyRequestModel){
    return this.post<ResultOfT<CompanyResponseModel>>('Company/AddCompany' , reqModel);
  }

  updateCompany(reqModel: CompanyRequestModel){
    return this.post<ResultOfT<CompanyResponseModel>>('Company/UpdateCompany' , reqModel);
  }

  deleteCompany(companyId: number) {
    return this.get<ResultOfT<boolean>>('Company/DeleteCompany/' + companyId);
  }

  testDocuwareConnection(reqModel: TestDocuwareConnectionModel){
    return this.post<ResultOfT<any>>('Company/TestDocuwareConnection', reqModel);
  }
  GenreateClientCredentials() {
    return this.get<ResultOfT<ClientCredentialsModel>>('Company/GenreateClientCredentials');
  }
  updateClientCredentials(reqModel: CompanyRequestModel) {
    return this.post<ResultOfT<boolean>>('Company/updateClientCredentials', reqModel);
  }
}
