<div class="document-cabinet-list">
  <div class="alert" [ngClass]="messageClass ? 'success' : 'warning'" *ngIf="displayMessage">
    <p>{{message}}</p>
    <a class="close-popup" href="javascript:void(0);" (click)="closeMessagePopup()">
      <img width="20"
           src="assets/img/close-ic.svg">
    </a>
  </div>
  <form class="form" [formGroup]="form" autocomplete="off">
    <div class="title-block model-heading">
      <h1 class="popup-heading" *ngIf="clientId == 0">Add Client</h1>
      <h1 class="popup-heading" *ngIf="clientId > 0">Edit Client</h1>
      <div class="switchbutton">
        <span class="switch-outer">
          <label class="switch">
            <!-- <input type="checkbox" [(ngModel)]="isActive" disabled [ngModelOptions]="{standalone: true}"
                checked> -->
            <input type="checkbox" [(ngModel)]="isActive" [ngModelOptions]="{standalone: true}"
                   checked>
            <span class="slider round"></span>
          </label>
          {{isActive ? 'Active' : 'Inactive'}}
        </span>

      </div>
    </div>

    <div class="add-customer-popup-inner">
      <div class="custom-scroll">
        <div class="general-info">
          <h2 class="pop-sub-heading">Client Information</h2>
          <div class="card-block">
            <div class="form__group">
              <label class="form__label">First Name</label>
              <input type="text" class="form__control" formControlName="FirstName">
              <span class="error"
                    *ngIf="hasError(formFields.FirstName, 'required') ">{{validationMessages.FirstName.required}}</span>
              <span class="error"
                    *ngIf="hasError(formFields.FirstName, 'validCharacters')">{{validationMessages.FirstName.validCharacters}}</span>
            </div>

            <div class="form__group">
              <label class="form__label">Last Name</label>
              <input type="text" class="form__control" formControlName="LastName">
              <span class="error"
                    *ngIf="hasError(formFields.LastName, 'required') ">{{validationMessages.LastName.required}}</span>
              <span class="error"
                    *ngIf="hasError(formFields.LastName, 'validCharacters')">{{validationMessages.LastName.validCharacters}}</span>
            </div>

            <div class="form__group">
              <label class="form__label">Email</label>
              <input type="text" class="form__control" formControlName="Email">
              <span class="error"
                    *ngIf="hasError(formFields.Email, 'required') ">{{validationMessages.Email.required}}</span>
              <span class="error"
                    *ngIf="hasError(formFields.Email, 'email')">{{validationMessages.Email.email}}</span>
            </div>
            <div class="form__group">
              <label class="form__label">Phone</label>
              <!-- <input type="text" class="form__control"> -->
              <p-inputMask class="form__phone" formControlName="PhoneNo" mask="(999) 999-9999">
              </p-inputMask>
              <span class="error"
                    *ngIf="hasError(formFields.PhoneNo, 'required') ">{{validationMessages.PhoneNo.required}}</span>
            </div>

            <!--<div class="form__group">
    <label class="form__label">Role</label>
    <p-dropdown class="select-dropdown topOpen" formControlName="Role" [options]="roleList" optionLabel="label" optionValue="label"
                placeholder="Select Role"></p-dropdown>
    <span class="error"
          *ngIf="hasError(formFields.Role, 'required') ">{{validationMessages.Role.required}}</span>
  </div>-->



            <div class="form__group">
              <label class="form__label">Subscription Start Date</label>
              <p-calendar appendTo="body" class="form__calendar" formControlName="SubscriptionStartDate" panelStyleClass="body-calendar" [showIcon]="true" (onSelect)="onDateSelect($event)">
              </p-calendar>
              <span class="error"
                    *ngIf="hasError(formFields.SubscriptionStartDate, 'required') ">{{validationMessages.SubscriptionStartDate.required}}</span>
            </div>

            <div class="form__group">
              <label class="form__label">Subscription End Date</label>
              <p-calendar appendTo="body" class="form__calendar" [minDate]="form.get(formFields.SubscriptionStartDate).value" formControlName="SubscriptionEndDate" (onSelect)="onSelectEndDate($event)" panelStyleClass="body-calendar" [showIcon]="true">
              </p-calendar>
              <span class="error"
                    *ngIf="hasError(formFields.SubscriptionEndDate, 'required') ">{{validationMessages.SubscriptionEndDate.required}}</span>
              <!-- <span class="error"
          *ngIf="subscriptionStartDate > subscriptionEndDate">{{validationMessages.SubscriptionEndDate.min}}</span> -->

            </div>
            <div class="form__group">
              <label class="form__label" style=" display: flex; ">
                Storage (In GB)
                <button class="btn-primary" type="button" style="height: inherit; font-size: 12px; width: auto; line-height: 0 !important; margin: 0 0 0 1.5rem; background-color: transparent; color: var(--primary-color); padding: 0;" (click)="ShowHideStorage()" *ngIf="isEditMode">{{StorageButton}}</button>
              </label>
              <input type="text" class="form__control" formControlName="AllowedDocumentStorage" *ngIf="!isEditMode" />
              <div *ngIf="isEditMode" style="display: inline-flex; width: 100%; align-items: center; min-height: 54px;">
                <span class="form__label" style=" min-width: 150px; width: auto; ">{{storageLabel}}</span>


                <span *ngIf="isShowStorage" class="form__label" style="display: inline-flex !important; width: auto; white-space: nowrap; align-items: center;">
                  <input type="text" class="form__control" formControlName="UpgradeStorage" (keypress)="onUpgradeStorage()" style=" margin-right: 1rem; " />(In GB)
                  <span class="error" *ngIf="validUpgradeStorage!=''">
                    {{validUpgradeStorage}}
                  </span>
                  <span class="error"
                        *ngIf="hasError(formFields.UpgradeStorage, 'validNumber')">{{validationMessages.UpgradeStorage.validNumber}}</span>
                </span>
              </div>
              <span class="error"
                    *ngIf="hasError(formFields.AllowedDocumentStorage, 'required') && !isEditMode">
                {{validationMessages.AllowedDocumentStorage.required}}
              </span>
              <span class="error"
                    *ngIf="hasError(formFields.AllowedDocumentStorage, 'validNumber') && !isEditMode">{{validationMessages.AllowedDocumentStorage.validNumber}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="general-info popup-bottom">
      <div class="button-block">
        <button class="btn-secondary" (click)="closePopup()" type="button">Cancel</button>
        <button class="btn-primary" type="button" (click)="onSubmit()">Save</button>
      </div>
    </div>

    <div class="loader-outer" *ngIf="loading">
      <div class="loader"></div>
    </div>

  </form>
</div>
