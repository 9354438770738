export class Constants {
  static readonly currentUserToken = 'currentUserToken';
  static readonly Menu = 'menu';

  static cookie = class {
    static readonly email = 'email';
    static readonly username = 'username';
    static readonly password = 'password';
  };

  static formControlUpdateStrategy = class {
    static readonly change = 'change';

    static readonly blur = 'blur';

    static readonly submit = 'submit';
  };
}
