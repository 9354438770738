export class MigrationClientResponseModel {
  ClientId: number;
  FirstName: string;
  LastName: string;
  Email: string
  PhoneNo: string;
  Password: string;
  Role: string;
  IsActive: boolean;
  IsForgotPassword: boolean;
  IsLinkExpired: boolean;
  IsDeleted: boolean;
  AllowedDocumentStorage: string;
  SubscriptionStartDate: Date;
  SubscriptionEndDate: Date;
  DateTimeCreated: Date;
  DateTimeLastModified: Date;
  Message: string;
  token: string;
  tokenExpiry: Date;
  tokenType: string;
  UsedDocumentStorage: string;
}
