<div class="login-outer">
    <div class="login-outer__block">
        <div class="login-outer__block__box">
            <h1>{{title}}</h1>
            <h2 *ngIf="displayForm">{{h2title}}</h2>

            <div class="alert" [ngClass]="messageClass ? 'success' : 'warning'" *ngIf="displayMessage">
                <p>{{message}}</p>
                <a class="close-popup" href="javascript:void(0);" (click)="closeMessagePopup()"><img width="20"
                        src="assets/img/close-ic.svg"> </a>
            </div>

            <ng-container *ngIf="!displayForm && passwordresetSuccessful ==0">
                <div class="expired-link">

                    <h2>{{message}}</h2>
                    
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                        <rect width="256" height="256" fill="none"/>
                        <line x1="128" x2="128" y1="104" y2="144" fill="none" stroke="#f42525" stroke-linecap="round" stroke-linejoin="round" stroke-width="10"/>
                        <path fill="none" stroke="#f42525" stroke-linecap="round" stroke-linejoin="round" stroke-width="10" d="M114.15243,39.98472,26.17616,191.977a16.00005,16.00005,0,0,0,13.84762,24.01535H215.97625A16,16,0,0,0,229.82386,191.977L141.84757,39.98472A16,16,0,0,0,114.15243,39.98472Z"/>
                        <circle cx="128" cy="180" r="8" fill="#f42525"/>
                    </svg>

                    <div class="forgot-password">
                        <a href="javascript:void(0);" routerLinkActive="active" routerLink="/forgot-password" >Forgot Password?</a>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="displayForm && passwordresetSuccessful==0">
            
                <form [formGroup]="form" autocomplete="off">
                    <div class="form__group">
                        <label class="form__label">New Password

                            <div class="password-info reset-pass">
                                <img class="password-info__img" src="assets/img/info-ic.svg" alt="info" width="22">
                                <ul class="password-info__box">
                                    <li>Password Suggestions</li>
                                    <li>At least one uppercase letter</li>
                                    <li>At least one lowercase letter</li>
                                    <li>At least one number</li>
                                    <li>At least one special character</li>
                                    <li>Minimum 8 characters long</li>
                                </ul>
                            </div>
                        </label>
                        <div class="login-inputpass">
                            <input type="password" class="form__control" formControlName="newPassword">
                            <span class="error"
                                *ngIf="hasError(formFields.newPassword, 'required')">{{validationMessages.password.required}}</span>
                            <span class="error"
                                *ngIf="hasError(formFields.newPassword, 'minLength')">{{validationMessages.password.minlength}}</span>
                            <span class="error"
                                *ngIf="hasError(formFields.newPassword, 'validStrongPassword')">{{validationMessages.password.invalid}}</span>
                        </div>


                    </div>
                    <div class="form__group">
                        <label class="form__label">Confirm Password</label>
                        <div class="login-inputpass">
                            <input class="form__control" type="password" formControlName="confirmPassword">
                        </div>
                        <span class="error"
                            *ngIf="hasError(formFields.confirmPassword, 'required')">{{validationMessages.confirmPassword.required}}</span>
                        <span class="error"
                            *ngIf="form.hasError('validPasswordMatch')">{{validationMessages.confirmPassword.validPasswordMatch}}</span>
                    </div>
                    <div class="form__group mb-0 center">
                        <button class="btn-primary" type="submit" (click)="onSubmit()">Submit</button>
                    </div>
                </form>
            </ng-container>

            <ng-container *ngIf="passwordresetSuccessful == 1">
                <div class="expired-link">

                    <h2>Thank You</h2>                    
                    <h2>Your Password has successfully been Updated.</h2>                    

                    
                </div>
            </ng-container>
        </div>
    </div>
</div>