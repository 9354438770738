import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthResponseModel } from 'src/app/models/response/access-token-response';
import { WebStorageService } from 'src/app/services/web-storage.service';
import { UserRole } from 'src/app/shared/enum';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.sass']
})
export class PageNotFoundComponent implements OnInit {

  currentUserToken:UserAuthResponseModel;
  isDocuware: boolean;
  companyId: number;

  constructor(private webStorageService: WebStorageService, private router: Router) { }

  ngOnInit(): void {
    this.currentUserToken = this.webStorageService.getCurrentUserToken();
    this.isDocuware = this.currentUserToken.isDocuware;
    this.companyId = this.currentUserToken.companyId;
  }

  goToHome() {
    if(this.currentUserToken.role === UserRole.enumCustomer) {
      // const url = '/company/' + this.currentUserToken.companyId + '/document-cabinet'
      
      const url = !this.isDocuware ? '/company/' + this.companyId + '/inbox' : '/company/' + this.companyId + '/configuration/document-filing-cabinets/document-cabinets';
      this.router.navigate([url]);

    } else {
      this.router.navigate(['/company']);
    } 
  }

}
