export class DocuwareMasterRequestModel {
  DocuwareId: number;
  CompanyId: number;
  MunicipalityId: number;
  OrganizationName: string;
  ServerURL: string;
  UserName: string;
  Password: string;
  PassPhrase: string;
  // CabinetId: number;
  // CabinetName: string;
  IsActive: boolean;
  IsDeleted: boolean;
  DateTimeCreated: Date;
  DateTimeLastModified: Date;
}
