import { DocuwareMasterResponseModel } from "./docuware-master-response";

export class CompanyResponseModel {
  CompanyId: number;
  CompanyCode: string;
  CompanyName: string;
  CompanyWebsiteURL: string;
  ContactFirstName: string;
  ContactLastName: string;
  ContactEmail: string;
  ContactPhone: string;
  InterfaceWith: string;
  AllowedDocumentStorage: string;
  IsActive: boolean;
  IsDeleted: boolean;
  IsPartnerApiEnabled: boolean;
  DateTimeCreated: Date;
  DateTimeLastModified: Date;

  UsedStorage: string;

  // DocuwareDetails: DocuwareMasterResponseModel;
  DocuwareMasters: DocuwareMasterResponseModel[];
  ClientId: string;
  ClientSecret: string;
}
