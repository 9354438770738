export class MetadataSearchRequestModel{
    DocumentTypeId: number;
    forCustomPage: boolean;
    MetadataSearch: Array<MetadataSearchModel>;
}

export class MetadataSearchModel {
    DocumentTypeMetadataId: number;
    FieldName: string;
    FieldType: string;
    FieldValueFrom: string;
    FieldValueTo: string;
}