import { GroupPermissionResponseModel } from "./group-permission-response";
import { GroupResponseModel } from "./group-response";
import { UserPermissionModelForResponse } from "./user-permission-response";

export class UserAuthResponseModel {
  token: string;
  tokentype: string;
  refreshtoken: string;
  userId: number;
  email: string;
  userName: string;
  userFirstName: string;
  userLastName: string;
  role: string;
  companyId: number;
  companyName: string;
  groupId: number;
  isDocuware: boolean;
  group: Array<GroupResponseModel>;
  userPermission: Array<UserPermissionModelForResponse>;
  groupPermission: Array<GroupPermissionResponseModel>;
}

export class AccessTokenResponseModel {
  access_token: string;
  token_type: string;
  expires_in: number;
  user_id: string;
  customer_id: string;
  company_id: string;
  user_type: string;
  product_name: string;
  name: string;
  email_id: string;
}
