import { DocuwareMasterRequestModel } from "./docuware-master-request";

export class CompanyRequestModel {
  CompanyId: number;
  CompanyCode: string;
  CompanyName: string;
  CompanyWebsiteURL: string;
  ContactFirstName: string;
  ContactLastName: string;
  ContactEmail: string;
  ContactPhone: string;
  InterfaceWith: string;
  AllowedDocumentStorage: string;
  IsActive: boolean;
  IsDeleted: boolean;
  IsPartnerApiEnabled: boolean;
  DateTimeCreated: Date;
  DateTimeLastModified: Date;

  // DocuwareDetails:DocuwareMasterRequestModel;
  DocuwareMasters: DocuwareMasterRequestModel[];
  clientId: string;
  clientSecret: string;
}
