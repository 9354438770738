import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { WebStorageService } from '../services/web-storage.service';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  urls: Array<string> = ['/signin', '/forgotpassword', '/AuthenticateForgotPasswordRequest', '/ResetPassword','/refresh'];
  IsValid: boolean = false;
  constructor(private webStorageService: WebStorageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    var token = "";
    if (this.webStorageService.getCurrentUserToken()) {
      token = this.webStorageService.getCurrentUserToken().token;
    }

    this.IsValid = false;
    this.urls.forEach(x => {
      if (request.url.toLowerCase().includes(x.toLowerCase())) {
        this.IsValid = true;
        return;
      }
    });
    if (token != "" && !this.IsValid) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          //'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
          //'Content-Type':'application/json'
        }
      });
    }
    return next.handle(request);
  }
}
