<div class="main-outer">
  <app-header *ngIf="!isCustomer"></app-header>
  <app-header-customer *ngIf="isCustomer"></app-header-customer>
    <div class="municipality-accounts">
        <div class="container">
            <div class="title-block">
              <h1>
                <a class="back-btns" (click)="onBack()" href="javascript:void(0);">
                  <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 8.2L7.98523 1V5C19.913 5 21.2891 12.7424 20.9578 17C20.4569 14.852 20.2243 11.4 7.98523 11.4V15.4L1 8.2Z" stroke="#414141" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    </a> 
                  Change Password
              </h1>
               
            </div>
           
            <div class="alert" [ngClass]="messageClass ? 'success' : 'warning'" *ngIf="displayMessage">
              <p>{{message}}</p>
              <a class="close-popup"  href="javascript:void(0);" (click)="closeMessagePopup()"><img width="20" src="assets/img/close-ic.svg"> </a>
            </div>
            <form [formGroup]="form" autocomplete="off">
              <div class="general-info">
                <div class="card-block">
                  <div class="form__group">
                    <label class="form__label">Current Password</label>
                    <input type="password" value="" class="form__control" formControlName="oldPassword">
                    <span class="error" *ngIf="hasError(formFields.oldPassword, 'required')">{{validationMessages.oldPassword.required}}</span>

                    <div class="password-info">
                      <img class="password-info__img" src="assets/img/info-ic.svg" alt="info" width="22">
                      <ul class="password-info__box">
                          <li>Password Suggestions</li>
                          <li>At least one uppercase letter</li>
                          <li>At least one lowercase letter</li>
                          <li>At least one number</li>
                          <li>At least one special character</li>
                          <li>Minimum 8 characters long</li>
                      </ul>
                  </div>
                  </div>
                  <div class="form__group blank">

                  </div>

                  <div class="form__group">
                    <label class="form__label">New Password </label>
                    <input type="password" value="" class="form__control" formControlName="newPassword">
                    <span class="error" *ngIf="hasError(formFields.newPassword, 'required')" >{{validationMessages.password.required}}</span>
                        <span class="error" *ngIf="hasError(formFields.newPassword, 'minLength')" >{{validationMessages.password.minlength}}</span>
                        <span class="error" *ngIf="hasError(formFields.newPassword, 'validStrongPassword')"  >{{validationMessages.password.invalid}}</span>
                  </div>

                  <div class="form__group">
                    <label class="form__label">Confirm Password</label>
                    <input type="password" value="" class="form__control" formControlName="confirmPassword">

                    <span class="error" *ngIf="hasError(formFields.confirmPassword, 'required')">{{validationMessages.confirmPassword.required}}</span>
                    <span class="error error-two-line" *ngIf="form.hasError('validPasswordMatch')">{{validationMessages.confirmPassword.validPasswordMatch}}</span>
                  </div>
                </div>

                <div class="button-block">
                  <button class="btn-primary" type="submit" (click)="onSubmit()">Update</button>
                </div>
              </div>
            </form>
        </div>
    </div>
</div>
