import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MunicipalityAccountsComponent } from './municipality-accounts/municipality-accounts.component';
import { ManageComponent } from './municipality-accounts/manage/manage.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { HeaderComponent } from './components/header/header.component';
import { DropdownMenuComponent } from './components/dropdown-menu/dropdown-menu.component';
import { DeletePopupComponent } from './components/delete-popup/delete-popup.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { PrimanyButtonComponent } from './components/buttons/primany-button/primany-button.component';
import { SecondaryButtonComponent } from './components/buttons/secondary-button/secondary-button.component';
import { AccentButtonComponent } from './components/buttons/accent-button/accent-button.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { SwitchButtonComponent } from './components/switch-button/switch-button.component';
import { TextboxComponent } from './components/textbox/textbox.component';
import { PasswordComponent } from './components/password/password.component';
import { BrowseComponent } from './components/browse/browse.component';
import { TextAreaComponent } from './components/text-area/text-area.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { TitleH1Component } from './components/title/title-h1/title-h1.component';
import { TitleH2Component } from './components/title/title-h2/title-h2.component';
import { TitleH3Component } from './components/title/title-h3/title-h3.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { LogoComponent } from './components/logo/logo.component';
import { SearchComponent } from './components/search/search.component';
import { ManageCabinetsComponent } from './municipality-accounts/manage-cabinets/manage-cabinets.component';
import { CustomWebPageComponent, FilterPipe } from './custom-web-page/custom-web-page.component';
import { SortDirective } from './directive/sort.directive';
import {DropdownModule } from 'primeng/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {SidebarModule} from 'primeng/sidebar';

import { InputMaskModule } from 'primeng/inputmask';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';

import {DpDatePickerModule} from 'ng2-date-picker';
import { ColorPickerModule } from 'ngx-color-picker';
// import { DragDropModule } from '@angular/cdk/drag-drop';
import { ManageCustomerComponent } from './municipality-accounts/manage-customer/manage-customer.component';
// import { HeaderCustomerComponent } from './components/header-customer/header-customer.component';
import { CustomerComponent } from './customer/customer.component';
import { GroupComponent } from './customer/group/group.component';
import { GroupManageComponent } from './customer/group/group-manage/group-manage.component';
import { CompanyComponent } from './inception/company/company.component';
import { CompanyManageComponent } from './inception/company/company-manage/company-manage.component';
import { DocumentCabinetComponent } from './document-cabinet/document-cabinet.component';
import { UserComponent } from './customer/user/user.component';
import { DocumentThemeSetupComponent } from './document-cabinet/document-theme-setup/document-theme-setup.component';
import { ManageDocumentCabinetComponent } from './document-cabinet/manage-document-cabinet/manage-document-cabinet.component';
import { UserManageComponent } from './customer/user/user-manage/user-manage.component';
import { PermissionComponent } from './customer/permission/permission.component';
import { ManageDocuwareCabinetComponent } from './document-cabinet/manage-docuware-cabinet/manage-docuware-cabinet.component';
import { DocumentTypeDocumentComponent } from './document-cabinet/document-type-document/document-type-document.component';
import { ManageDocumentTypeDocumentComponent } from './document-cabinet/manage-document-type-document/manage-document-type-document.component';
import { InceptionCustomWebPageComponent } from './inception-custom-web-page/inception-custom-web-page.component';
import {OverlayPanelModule } from 'primeng/overlaypanel';
import { SubMenuComponent } from './components/sub-menu/sub-menu.component';
import { DocumentInboxComponent } from './document-cabinet/document-inbox/document-inbox.component';
import {DialogModule} from 'primeng/dialog';
// import { DragAndDropDirective } from './directive/drag-and-drop.directive';
import {AutoCompleteModule} from 'primeng/autocomplete';
import { TileDocumentCabinetComponent } from './document-cabinet/tile-document-cabinet/tile-document-cabinet.component';
import { TileDocumentTypeComponent } from './document-cabinet/tile-document-type/tile-document-type.component';
import { DocumentCabinetsMenuComponent } from './new-workflow-design/document-cabinets-menu/document-cabinets-menu.component';
import { ConfigurationModule } from './new-workflow-design/configuration/configuration.module';
import {MultiSelectModule} from 'primeng/multiselect';
import { SharedModule } from './shared/shared.module';
import { HeadersInterceptor } from './shared/headers.interceptor';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { MigrationCLientComponent } from './new-workflow-design/migration-client/migration-client.component';
import { ManageMigrationCLientComponent } from './new-workflow-design/migration-client/manage-migration-client/manage-migration-client.component';
import { ResetMigrationClientPasswordComponent } from './reset-migration-client-password/reset-migration-client-password.component';
import { ForgotMigrationClientPasswordComponent } from './forgot-migration-client-password/forgot-migration-client-password.component';
// declare module "@angular/core" {
//   interface ModuleWithProviders<T = any> {
//     ngModule: Type<T>;
//     providers?: Provider[];
//   }
// }

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    MunicipalityAccountsComponent,
    ManageComponent,
    MyProfileComponent,
    ChangePasswordComponent,
    //HeaderComponent,
    //DropdownMenuComponent,
    DeletePopupComponent,
    EmptyStateComponent,
    PrimanyButtonComponent,
    SecondaryButtonComponent,
    AccentButtonComponent,
    CheckboxComponent,
    SwitchButtonComponent,
    TextboxComponent,
    PasswordComponent,
    BrowseComponent,
    TextAreaComponent,
    ColorPickerComponent,
    TitleH1Component,
    TitleH2Component,
    TitleH3Component,
    PageNotFoundComponent,
    LogoComponent,
    SearchComponent,
    ManageCabinetsComponent,
    CustomWebPageComponent,
    SortDirective,
    FilterPipe,
    ManageCustomerComponent,
    // HeaderCustomerComponent,
    CustomerComponent,
    GroupComponent,
    GroupManageComponent,
    CompanyComponent,
    CompanyManageComponent,
    DocumentCabinetComponent,
    UserComponent,
    DocumentThemeSetupComponent,
    ManageDocumentCabinetComponent,
    UserManageComponent,
    PermissionComponent,
    // ManageDocuwareCabinetComponent,
    DocumentTypeDocumentComponent,
    ManageDocumentTypeDocumentComponent,
    InceptionCustomWebPageComponent,
    SubMenuComponent,
    DocumentInboxComponent,
    // DragAndDropDirective,
    TileDocumentCabinetComponent,
    TileDocumentTypeComponent,
    MigrationCLientComponent,
    ManageMigrationCLientComponent,
    ResetMigrationClientPasswordComponent,
    ForgotMigrationClientPasswordComponent,
    // DocumentCabinetsMenuComponent,

  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ColorPickerModule,
    DpDatePickerModule,
    // DragDropModule,
    DropdownModule,
    MultiSelectModule,
    BrowserAnimationsModule,
    TableModule,
    FileUploadModule,
    InputNumberModule,
    InputMaskModule,
    TooltipModule,
    ButtonModule,
    InputTextModule,
    CalendarModule,
    RadioButtonModule,
    OverlayPanelModule,
    SidebarModule,
    DialogModule,
    AutoCompleteModule,
    ConfigurationModule,
    SharedModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    FilterPipe,
    // DragDropModule,
    DropdownModule,
    MultiSelectModule,
    TableModule,
    FileUploadModule,
    InputNumberModule,
    InputMaskModule,
    TooltipModule,
    ButtonModule,
    InputTextModule,
    CalendarModule,
    RadioButtonModule,
    OverlayPanelModule,
    SidebarModule,
    DialogModule,
    AutoCompleteModule,
    //ColorPickerModule,
    DocumentThemeSetupComponent,
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
