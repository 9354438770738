import { Injectable } from '@angular/core';
import { UserInfoRequestModel } from '../models/request/userInfo-request';
import { BaseService } from './base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResultOfT } from '../models/response/result';
import { ClientCredentialsModel, UserInfoResponseModel } from '../models/response/userInfo-response';
import { WebStorageService } from './web-storage.service';
import { Router } from '@angular/router';
import { ForgotPasswordModel, ResetPasswordModel, ChangePasswordModel } from '../models/response/forgot-password';
import { MunicipalityRequestModel } from '../models/request/municipality-request';
import { MunicipalityResponseModel } from '../models/response/municipality-response';
import { Observable, Subject } from 'rxjs';
import { AccessTokenResponseModel } from '../models/response/access-token-response';
import { UserPermissionRequestModel } from '../models/request/user-permission-request';
import { UserPermissionResponseModel } from '../models/response/user-permission-response';
import { PermissionByDocTypeRequestModel } from '../models/request/document-type-request';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  constructor(httpService: HttpClient, private webStorageService: WebStorageService,
    private router: Router) {
    super(httpService);
  }

  signIn(reqModel: UserInfoRequestModel) {
    return this.post<ResultOfT<UserInfoResponseModel>>('User/SignIn', reqModel);
  }

  signIn1(username: string, password: string) {
    const userData = 'username=' + username + '&password=' + password + '&grant_type=password';

    return this.post<ResultOfT<AccessTokenResponseModel>>('token', userData, new HttpHeaders(
      {
        'Content-Type': 'application/x-www-form-urlencoded',
      }));
  }

  signOut(redirectUrl: string = null) {
    this.webStorageService.deleteCurrentUserToken();
    this.router.navigate(['/login']);

    //this.onSignInOut.next(Constants.userType.none);
  }

  forgotPassword(reqModel: ForgotPasswordModel) {
    return this.post<ResultOfT<any>>('User/ForgotPassword', reqModel);
  }

  authenticateForgotPasswordRequest(reqModel: ForgotPasswordModel) {
    return this.post<ResultOfT<UserInfoResponseModel>>('User/AuthenticateForgotPasswordRequest', reqModel);
  }

  resetPassword(reqModel: ResetPasswordModel) {
    return this.post<ResultOfT<any>>('User/ResetPassword', reqModel);
  }

  updateProfile(reqModel: UserInfoRequestModel) {
    return this.post<ResultOfT<any>>('User/UpdateProfile', reqModel);
  }

  ChangePassword(reqModel: ChangePasswordModel) {
    return this.post<ResultOfT<boolean>>('User/ChangePassword', reqModel);
  }

  addNewCustomer(reqModel: MunicipalityRequestModel) {
    return this.post<ResultOfT<MunicipalityResponseModel>>('User/AddNewCustomer', reqModel);
  }

  getAllUsers(companyId: number) {
    return this.get<ResultOfT<UserInfoResponseModel[]>>('User/GetAllUser/' + companyId);
  }

  getUsers(userId: number) {
    return this.get<ResultOfT<UserInfoResponseModel>>('User/GetUser/' + userId);
  }

  saveUser(reqModel: UserInfoRequestModel) {
    return this.post<ResultOfT<boolean>>('User/AddUser', reqModel);
  }

  updateUser(reqModel: UserInfoRequestModel) {
    return this.post<ResultOfT<boolean>>('User/UpdateUser', reqModel);
  }
  
  deleteUser(userId: number) {
    return this.get<ResultOfT<boolean>>('User/DeleteUser/' + userId);
  }

  addUpdateUserPermission(reqModel: UserPermissionRequestModel) {
    return this.post<ResultOfT<boolean>>('User/AddUpdateUserPermissions', reqModel);
  }

  sendEmailOnNewUser(reqModel: UserInfoRequestModel) {
    return this.post<ResultOfT<boolean>>('User/SendEmailNewUser', reqModel);
  }

  getUserpermissions(userId: number) {
    return this.get<ResultOfT<UserPermissionResponseModel>>('User/GetUserPermissions/' + userId);
  }

  // Start: New work flow
  getUserForGroupCreation(companyId: number) {
    return this.get<ResultOfT<UserInfoResponseModel[]>>('User/GetUserForGroupCreation/' +  companyId);
  }

  getUsersForAssignUserPermission(reqModel: PermissionByDocTypeRequestModel) {
    return this.post<ResultOfT<UserInfoResponseModel[]>>('User/GetUsersForAssignUserPermission' , reqModel);
  }
 
  resendUserPasswordResetLink(userId: number) {
    return this.post<ResultOfT<any>>('User/ResendUserPasswordResetLink/'+userId, null);
  }
  // End
}
