<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Listing</title>
  <link rel="stylesheet" href="css/style.css">
</head>

<div id="main-outer" class="main-outer">

  <header class="header" [ngStyle]="{'background-color': documentTypeResponseModel?.HeaderBGColor}">
    <div class="container">
      <a href="#" class="logo" *ngIf="!documentTypeResponseModel.LogoImage">Logo</a>
      <img *ngIf="documentTypeResponseModel.LogoImage" [(src)]="cardImageBase64" />
      <small>{{companyResponseModel?.CompanyName}}</small>
    </div>
  </header>

  <div class="alert" [ngClass]="messageClass ? 'success' : 'warning'" *ngIf="displayMessage">
    <p>{{message}}</p>
    <a class="close-popup" href="javascript:void(0);" (click)="closeMessagePopup()">
      <img width="20" src="assets/img/close-ic.svg">
    </a>
  </div>

  <div class="content-block" [ngClass]="filterResposniveEvent ? 'active-sidebar' : ''">
    <div class="content-block__filter">
      <a class="close-popup close-popup-filter" href="javascript:void(0);" (click)="filterClickEvent()"><img width="14" src="assets/img/close-ic.svg"> </a>

      <div *ngIf="documentTypeList?.length > 0 && !forSepcialCompanyDb && !isDocuware" style="padding-bottom: 32px;">
        <label class="form__label">Document Type</label>
        <p-dropdown appendTo="body" panelStyleClass="body-dropdown" [(ngModel)]="selectedDocumentType" [ngModelOptions]="{standalone: true}" 
        class="select-dropdown" placeholder="Select" [options]="documentTypeList" (onChange)="onDocumentTypeChange()"
        [showClear]="true" optionLabel="DocumentTypeName"></p-dropdown>
      </div>

      <ng-container *ngIf="showSearchPanel">
        <form class="form" [formGroup]="form" autocomplete="off">
          <div class="form__scroll custom-webpage-left-filter">

            <!-- <h2>Search</h2> -->
            <!-- <ng-container *ngIf="searchInputList?.length > 0 && headers?.length > 2"> -->
            <ng-container *ngIf="searchInputList?.length > 0 && !forSepcialCompanyDb && isDocuware">
              <h2>Search</h2>
              <ng-container *ngFor="let sBox of searchInputList; let i = index; let last = last">
                <div class="form__group">
                  <label class="form__label">{{sBox.DisplayAs}}</label>
                  <input *ngIf="sBox.FieldType !== 'Date' && sBox.DisplayAs == 'Full Text'" type="text" value=""
                         class="form__control" formControlName="{{sBox?.FieldName}}" />

                  <!-- <ng-container *ngIf="sBox.FieldType !== enumFieldTypes.enumCurrency"> -->
                  <!-- <p-autoComplete class="form__autocomplete" *ngIf="sBox.FieldType !== 'Date' && sBox.DisplayAs !== 'Full Text'"
                          formControlName="{{sBox?.FieldName}}" showEmptyMessage="true" [suggestions]="sBox.FilteredValues"
                          (completeMethod)="filterAutoCompleteSuggestion($event, sBox)" field="label" [minLength]="1"
                          (onSelect)="onSelectChange($event,sBox)" (onClear)="onSelectChange($event, sBox)">
          </p-autoComplete> -->
                  <!-- </ng-container> -->

                  <p-dropdown appendTo="body" panelStyleClass="dropdown-panel" class="form__dropdown" *ngIf="sBox.FieldType !== 'Date' && sBox.DisplayAs !== 'Full Text'"
                              [options]="sBox.Values" placeholder="Select" (onChange)="onSelectChange($event, sBox)"
                              formControlName="{{sBox?.FieldName}}" [filter]="true" [showClear]="true" [resetFilterOnHide]="true">
                  </p-dropdown>


                  <!-- <p-dropdown appendTo="body" panelStyleClass="dropdown-panel" class="form__dropdown" *ngIf="sBox.FieldType !== 'Date' && sBox.DisplayAs !== 'Full Text'"
            [options]="sBox.Values" placeholder="Select" (onChange)="onSelectChange(sBox)"
            formControlName="{{sBox?.FieldName}}" [filter]="true" [showClear]="true" [resetFilterOnHide]="true">
          </p-dropdown> -->
                  <!-- <input *ngIf="sBox.FieldType === 'Date'" [dpDayPicker]="config" type="text" value=""
          class="form__control" formControlName="{{sBox?.FieldName}}" /> -->
                  <p-calendar  [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearRange"  appendTo="body" *ngIf="sBox.FieldType === 'Date'" class="form__calendar" formControlName="{{sBox?.FieldName}}" [showIcon]="true" (onInput)="onSelectChange($event, sBox)"></p-calendar>
                </div>
                <!-- <div class="form__group" *ngIf="i > 3"
          [ngStyle]="isMore ? { 'display' : 'block' } :  { 'display' : 'none' }">
          <label class="form__label">{{sBox.DisplayAs}}</label>

          <input *ngIf="sBox.FieldType !== 'Date' && sBox.DisplayAs == 'Full Text'" type="text" value=""
            class="form__control" formControlName="{{sBox?.FieldName}}" />

          <p-dropdown appendTo="body" panelStyleClass="dropdown-panel" class="form__dropdown" *ngIf="sBox.FieldType !== 'Date' && sBox.DisplayAs !== 'Full Text'"
            [options]="sBox.Values" placeholder="select" (onChange)="onSelectChange(sBox)"
            formControlName="{{sBox?.FieldName}}" [filter]="true" [showClear]="true" [resetFilterOnHide]="true">
          </p-dropdown>
          <input *ngIf="sBox.FieldType === 'Date'" [dpDayPicker]="config" type="text" value=""
            class="form__control" formControlName="{{sBox?.FieldName}}" />
        </div> -->
                {{last ? callFunction(i) : ''}}
              </ng-container>
            </ng-container>
            <!-- <ng-container *ngIf="searchInputList?.length > 0 && searchRecords?.length > 0 && !forSepcialCompanyDb && !isDocuware"> -->
            <ng-container *ngIf="searchInputList?.length > 0 && backupRecords?.length > 0 && !forSepcialCompanyDb && !isDocuware">
              <h2>Search</h2>
              <ng-container *ngFor="let sBox of searchInputList; let i = index; let last = last">
                <div class="form__group" *ngIf="sBox.FieldType !== 'Integer' && sBox.FieldType !== 'Date' && sBox.FieldType !== 'Decimal'&& sBox.FieldType !== 'Currency'">
                  <label class="form__label">{{sBox.DisplayAs}}</label>
                  <input *ngIf="sBox.FieldType !== 'Date' && sBox.DisplayAs == 'Full Text'" type="text" value=""
                         class="form__control" formControlName="{{sBox?.FieldName}}" />

                  <!-- <ng-container *ngIf="sBox.FieldType !== enumFieldTypes.enumCurrency"> -->
                  <!-- <p-autoComplete class="form__autocomplete" *ngIf="sBox.FieldType !== 'Date' && sBox.DisplayAs !== 'Full Text'"
                          formControlName="{{sBox?.FieldName}}" showEmptyMessage="true" [suggestions]="sBox.FilteredValues"
                          (completeMethod)="filterAutoCompleteSuggestion($event, sBox)" field="label" [minLength]="1"
                          (onSelect)="onSelectChange(sBox)" (onClear)="onSelectChange(sBox)">
          </p-autoComplete> -->

                  <p-autoComplete class="form__autocomplete" *ngIf="sBox.FieldType !== 'Date' && sBox.DisplayAs !== 'Full Text' && sBox.FieldType !== 'List'"
                                  formControlName="{{sBox?.FieldName}}" showEmptyMessage="true" [suggestions]="FilterValues"
                                  (completeMethod)="filterAutoCompleteSuggestion($event, sBox)" field="label" [minLength]="2"
                                  (onSelect)="onSelectChange($event, sBox)" (onClear)="onSelectChange($event,sBox)" [forceSelection]="true">
                  </p-autoComplete>

                  <p-dropdown class="select-dropdown"
                              *ngIf="sBox.FieldType==='List'"
                              field="label"
                              [formControl]="form.controls[sBox?.FieldName]"
                              (onChange)="onDropdownChange($event, sBox)"
                              appendTo="body" [filter]="true" [options]="BindDropdown(sBox?.FieldName)" optionLabel="label" optionValue="value">
                  </p-dropdown>
                  <!-- </ng-container> -->
                  <!-- <p-calendar appendTo="body" *ngIf="sBox.FieldType === 'Date'" class="form__calendar" formControlName="{{sBox?.FieldName}}" [showIcon]="true" (onInput)="onSelectChange(sBox)"></p-calendar> -->
                </div>
                <div class="form__group" *ngIf="sBox.FieldType === 'Integer' || sBox.FieldType === 'Date' || sBox.FieldType === 'Decimal' || sBox.FieldType === 'Currency'">

                  <label class="form__label">{{sBox.DisplayAs + ' From'}}</label>
                  <input type="text" *ngIf="sBox.FieldType !== 'Date'" value="" class="form__control" formControlName="{{sBox?.FieldName + 'From'}}" />
                  <p-calendar [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearRange"  appendTo="body" *ngIf="sBox.FieldType === 'Date'" class="form__calendar" formControlName="{{sBox?.FieldName + 'From'}}" [showIcon]="true"></p-calendar>
                </div>
                <div class="form__group" *ngIf="sBox.FieldType === 'Integer' || sBox.FieldType === 'Date' || sBox.FieldType === 'Decimal' || sBox.FieldType === 'Currency'">
                  <label class="form__label">{{sBox.DisplayAs + ' To'}}</label>
                  <input type="text" *ngIf="sBox.FieldType !== 'Date'" value="" class="form__control" formControlName="{{sBox?.FieldName + 'To'}}" />
                  <p-calendar  [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearRange"  appendTo="body" *ngIf="sBox.FieldType === 'Date'" class="form__calendar" formControlName="{{sBox?.FieldName + 'To'}}" [showIcon]="true"></p-calendar>

                </div>
                {{last ? callFunction(i) : ''}}
              </ng-container>
            </ng-container>
            <ng-container *ngIf="searchableFieldModel?.length > 0 && forSepcialCompanyDb">
              <h2>Search</h2>
              <ng-container *ngFor="let sBox of searchableFieldModel; let i = index; let last = last">
                <div class="form__group">
                  <label class="form__label">{{sBox.DisplayAs}}</label>
                  <input *ngIf="sBox.FieldType !== 'Date' && sBox.DisplayAs == 'Full Text'" type="text" value=""
                         class="form__control" formControlName="{{sBox?.FieldName}}" />

                  <!-- <p-autoComplete class="form__autocomplete" *ngIf="sBox.FieldType !== 'Date' && sBox.DisplayAs !== 'Full Text'" formControlName="{{sBox?.FieldName}}" showEmptyMessage="true"
           [suggestions]="sBox.SearchFieldValuesFiltered" (completeMethod)="filterAutoCompleteSuggestionForSpecialCompanies($event, sBox)" field="FieldValue" [minLength]="1" (onSelect)="onSelectChange($event, sBox)"
           (onClear)="onSelectChange($event, sBox)">
          </p-autoComplete> -->

                  <p-autoComplete class="form__autocomplete"
                                  *ngIf="sBox.FieldType !== 'Date' && sBox.DisplayAs !== 'Full Text' && sBox.FieldType !== 'List'"
                                  formControlName="{{sBox?.FieldName}}" showEmptyMessage="true" [suggestions]="FilterValuesForSpecialCompanies"
                                  (completeMethod)="filterAutoCompleteSuggestionForSpecialCompanies($event, sBox)" field="FieldValue"
                                  [minLength]="1" (onSelect)="onSelectChange($event, sBox)"
                                  (onClear)="onSelectChange($event, sBox)" [forceSelection]="true" appendTo="body">
                  </p-autoComplete>


                  <p-dropdown class="select-dropdown"
                              *ngIf="sBox.FieldType==='List'"
                              field="label"
                              [formControl]="form.controls[sBox?.FieldName]"
                              (onChange)="onDropdownChange($event, sBox)"
                              appendTo="body" [filter]="true" [options]="BindDropdown(sBox?.FieldName)" optionLabel="label" optionValue="value">
                  </p-dropdown>

                  <p-calendar  [monthNavigator]="true" [yearNavigator]="true" [yearRange]="yearRange"  *ngIf="sBox.FieldType === 'Date'" class="form__calendar" formControlName="{{sBox?.FieldName}}" [showIcon]="true" (onInput)="onSelectChange($event, sBox)"></p-calendar>
                </div>
                {{last ? callFunction(i) : ''}}
              </ng-container>
            </ng-container>
          </div>
         
          <div class="form__group btns" *ngIf="!searchLoader">
            <button class="btn btn-primary" (click)="onSearch()" [ngStyle]="{'background-color' : documentTypeResponseModel?.PageTitleColor}">Search</button>
            <a href="javascript:void(0);" *ngIf="displayClearButton" class="btn btn-secondary" (click)="onReset()">Clear</a>
          </div>

          <!-- <div class="loader-outer" *ngIf="searchLoader">
    <div class="loader"></div>
  </div> -->

        </form>

        <!-- <div *ngIf="!isMore && searchInputList?.length > 4" class="more-btns" (click)="isMore = true">
          <a href="javascript:void(0);" class="more-btn">+ More</a>
        </div>
        <div *ngIf="isMore && searchInputList?.length > 4" class="more-btns" (click)="isMore = false">          
          <a href="javascript:void(0);" class="more-btn">- Less</a>
        </div> -->
      </ng-container>
    </div>
    <div class="content-block__customer-content">
      <div class="responsive-filter">
        <a href="javascript:void(0);" class="mobile-block" (click)="filterClickEvent()">
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20"><path fill="none" fill-rule="evenodd" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 0H0l8 9.46V16l4 2V9.46z" transform="translate(1 1)"/></svg>
        </a>
      <h2 [ngStyle]="{'color' : documentTypeResponseModel?.PageTitleColor}">Documents </h2>
      
    </div>

      <h3 [ngStyle]="{'color' : documentTypeResponseModel?.DocumentGroupTitleColor}">
        {{customAuthResponseModel?.DocumentType?.DocumentTypeName}}
      </h3>

      <div class="document-list-outer">
        
        <div class="table-responsive" *ngIf="isDocuware">
          <table class="tables sortable custom-table" style="background-color: white;">
             <ng-container *ngIf="headers?.length > 2  && searchRecords?.length > 0">
              <thead [ngStyle]="{'background-color' : documentTypeResponseModel?.TableHeaderBGColor}">
                <tr>
                  <ng-container *ngFor="let header of headers">
                    <th width="100" class="text-center" *ngIf="header.headerName === 'View'"
                      [ngStyle]="{'color' : documentTypeResponseModel?.TableHeaderLabelColor, 'background-color' : documentTypeResponseModel?.TableHeaderBGColor}">
                      View
                    </th>

                    <ng-container *ngIf="header.headerName !== 'View'">
                      <th
                        [ngStyle]="{'color' : documentTypeResponseModel?.TableHeaderLabelColor, 'background-color' : documentTypeResponseModel?.TableHeaderBGColor}"
                        [(appSort)]="searchRecords" data-order="desc" [attr.data-name]="header.headerName" scope="col"
                        *ngIf="!(header.headerName === 'DocumentId')">
                        {{header.headerName}}
                      </th>
                    </ng-container>

                  </ng-container>
                </tr>
              </thead>
            </ng-container>
            <tbody>

              <ng-container *ngIf="searchRecords?.length > 0">
                <tr *ngFor="let record of searchRecords">
                  <td class="text-center">
                    <a class="link" (click)="onDownload(record)" href="javascript:void(0);">                     
                        <!-- <svg class="view-svg" xmlns="http://www.w3.org/2000/svg" width="512" height="512" enable-background="new 0 0 512 512" viewBox="0 0 512 512"><path d="M256,406c-30.6,0-62.2-7.6-94-22.5c-24.9-11.7-50-27.9-74.6-48.2C45.9,301,20.3,267,19.2,265.6
                          c-4.3-5.7-4.3-13.5,0-19.2c1.1-1.4,26.7-35.4,68.2-69.7c24.6-20.3,49.7-36.5,74.6-48.2c31.8-14.9,63.4-22.5,94-22.5
                          s62.2,7.6,94,22.5c24.9,11.7,50,27.9,74.6,48.2c41.5,34.3,67.2,68.3,68.2,69.7c4.3,5.7,4.3,13.5,0,19.2
                          c-1.1,1.4-26.7,35.4-68.2,69.7c-24.6,20.3-49.7,36.5-74.6,48.2C318.2,398.4,286.6,406,256,406z M52.6,256
                          c25.1,29.7,108,118,203.4,118c95.6,0,178.3-88.3,203.4-118c-25.1-29.7-108-118-203.4-118C160.4,138,77.7,226.3,52.6,256z"/><path d="M256,328c-39.7,0-72-32.3-72-72s32.3-72,72-72s72,32.3,72,72S295.7,328,256,328z M256,216
                          c-22.1,0-40,17.9-40,40s17.9,40,40,40s40-17.9,40-40S278.1,216,256,216z"/></svg> -->

                          <svg id="Capa_1" class="view-svg" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g>
                            <path d="m15 91c8.284 0 15-6.716 15-15v-46h46c8.284 0 15-6.716 15-15s-6.716-15-15-15h-61c-8.284 0-15 6.716-15 15v61c0 8.284 6.716 15 15 15z"/><path d="m15 512h61c8.284 0 15-6.716 15-15s-6.716-15-15-15h-46v-46c0-8.284-6.716-15-15-15s-15 6.716-15 15v61c0 8.284 6.716 15 15 15z"/>
                            <path d="m497 91c8.284 0 15-6.716 15-15v-61c0-8.284-6.716-15-15-15h-61c-8.284 0-15 6.716-15 15s6.716 15 15 15h46v46c0 8.284 6.716 15 15 15z"/><path d="m497 421c-8.284 0-15 6.716-15 15v46h-46c-8.284 0-15 6.716-15 15s6.716 15 15 15h61c8.284 0 15-6.716 15-15v-61c0-8.284-6.716-15-15-15z"/>
                            <path d="m256 361c57.897 0 105-47.103 105-105s-47.103-105-105-105-105 47.103-105 105 47.103 105 105 105zm0-180c41.355 0 75 33.645 75 75s-33.645 75-75 75-75-33.645-75-75 33.645-75 75-75z"/><path d="m256 301c24.813 0 45-20.187 45-45s-20.187-45-45-45-45 20.187-45 45 20.187 45 45 45zm0-60c8.271 0 15 6.729 15 15s-6.729 15-15 15-15-6.729-15-15 6.729-15 15-15z"/><path d="m76.023 342.381c50.592 43.433 113.077 78.619 179.977 78.619 66.903 0 129.389-35.19 179.977-78.619 44.516-38.216 72.029-76.003 73.179-77.593 3.793-5.246 3.793-12.332 0-17.577-1.149-1.59-28.663-39.377-73.179-77.593-50.592-43.432-113.077-78.618-179.977-78.618-66.903 0-129.389 35.19-179.977 78.619-44.516 38.216-72.029 76.003-73.179 77.593-3.793 5.246-3.793 12.332 0 17.577 1.15 1.589 28.664 39.377 73.179 77.592zm179.977-221.381c63.673 0 122.116 38.583 159.933 70.951 29.612 25.345 51.609 51.001 62.093 64.044-10.341 12.847-31.897 37.957-61.025 63.071-41.709 35.962-98.941 71.934-161.001 71.934-63.673 0-122.116-38.583-159.933-70.951-29.614-25.347-51.612-51.004-62.093-64.044 10.341-12.847 31.898-37.956 61.026-63.071 41.708-35.962 98.94-71.934 161-71.934z"/></g>
                        </svg>
                    </a>
                  </td>

                  <ng-container *ngFor="let header of headers">
                    <td
                      *ngIf="(header.fieldType !== 'Date') && (header.headerName !== 'DocumentId') && (header.headerName !== 'View')">
                      {{record[header.headerName]}}
                    </td>

                    <td
                      *ngIf="(header.fieldType == 'Date') && (header.headerName !== 'DocumentId') && (header.headerName !== 'View')">
                      {{record[header.headerName] | date: 'MM/dd/yyyy'}}
                    </td>

                  </ng-container>
                </tr>
              </ng-container>

              <ng-container *ngIf="searchRecords?.length === 0">
                <tr>
                  <td colspan="6" style="text-align: left !important;">No records found.</td>
                </tr>
              </ng-container>

            </tbody>
            <ng-container *ngIf=" !loading && !searchRecords && !isInitialEmptyState">
              No records found
            </ng-container>
            <ng-container *ngIf="!loading && !searchRecords && isInitialEmptyState">
              <!-- No records found. -->
              Please apply search to proceed.
            </ng-container>
          </table>          
        </div>       

        <ng-container *ngIf="!isDocuware && documentTypeResponseModel">
          <div class="table-responsive custom-web-page-table">
            <p-table [columns]="headers" [value]="searchRecords" class="tables table"
              (sortFunction)="customSort($event)" [customSort]="true">
              <ng-template pTemplate="header" let-columns>
                <!-- <tr *ngIf="searchRecords?.length > 0"  > -->
                <tr [ngStyle]="{'background-color' : documentTypeResponseModel?.TableHeaderBGColor}" *ngIf="searchRecords?.length > 0" >
                  <ng-container *ngFor="let header of headers">
                    <th width="120" class="text-left" *ngIf="header.headerName === 'View'" 
                    [ngStyle]="{'color' : documentTypeResponseModel?.TableHeaderLabelColor, 'background-color' : documentTypeResponseModel?.TableHeaderBGColor}">
                      View
                    </th>

                    <ng-container *ngIf="header.headerName !== 'View'">
                      <th *ngIf="!(header.headerName === 'DocumentId')" [pSortableColumn]="header.headerName"
                      [ngStyle]="{'color' : documentTypeResponseModel?.TableHeaderLabelColor, 'background-color' : documentTypeResponseModel?.TableHeaderBGColor}">
                        {{header.headerName}}
                      </th>
                    </ng-container>

                  </ng-container>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-record let-columns="headers">
                <tr *ngIf="searchRecords?.length > 0">
                  <td class="text-left">
                    <a class="link" (click)="onView(record['DocumentTypeInboxId'])"
                      href="javascript:void(0);">
                    <!-- <a class="link" (click)="onView(record['DocumentTypeDocumentId'])"
                      href="javascript:void(0);"> -->
                      <svg id="Capa_1" class="view-svg" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g>
                        <path d="m15 91c8.284 0 15-6.716 15-15v-46h46c8.284 0 15-6.716 15-15s-6.716-15-15-15h-61c-8.284 0-15 6.716-15 15v61c0 8.284 6.716 15 15 15z"/><path d="m15 512h61c8.284 0 15-6.716 15-15s-6.716-15-15-15h-46v-46c0-8.284-6.716-15-15-15s-15 6.716-15 15v61c0 8.284 6.716 15 15 15z"/>
                        <path d="m497 91c8.284 0 15-6.716 15-15v-61c0-8.284-6.716-15-15-15h-61c-8.284 0-15 6.716-15 15s6.716 15 15 15h46v46c0 8.284 6.716 15 15 15z"/><path d="m497 421c-8.284 0-15 6.716-15 15v46h-46c-8.284 0-15 6.716-15 15s6.716 15 15 15h61c8.284 0 15-6.716 15-15v-61c0-8.284-6.716-15-15-15z"/>
                        <path d="m256 361c57.897 0 105-47.103 105-105s-47.103-105-105-105-105 47.103-105 105 47.103 105 105 105zm0-180c41.355 0 75 33.645 75 75s-33.645 75-75 75-75-33.645-75-75 33.645-75 75-75z"/><path d="m256 301c24.813 0 45-20.187 45-45s-20.187-45-45-45-45 20.187-45 45 20.187 45 45 45zm0-60c8.271 0 15 6.729 15 15s-6.729 15-15 15-15-6.729-15-15 6.729-15 15-15z"/><path d="m76.023 342.381c50.592 43.433 113.077 78.619 179.977 78.619 66.903 0 129.389-35.19 179.977-78.619 44.516-38.216 72.029-76.003 73.179-77.593 3.793-5.246 3.793-12.332 0-17.577-1.149-1.59-28.663-39.377-73.179-77.593-50.592-43.432-113.077-78.618-179.977-78.618-66.903 0-129.389 35.19-179.977 78.619-44.516 38.216-72.029 76.003-73.179 77.593-3.793 5.246-3.793 12.332 0 17.577 1.15 1.589 28.664 39.377 73.179 77.592zm179.977-221.381c63.673 0 122.116 38.583 159.933 70.951 29.612 25.345 51.609 51.001 62.093 64.044-10.341 12.847-31.897 37.957-61.025 63.071-41.709 35.962-98.941 71.934-161.001 71.934-63.673 0-122.116-38.583-159.933-70.951-29.614-25.347-51.612-51.004-62.093-64.044 10.341-12.847 31.898-37.956 61.026-63.071 41.708-35.962 98.94-71.934 161-71.934z"/></g>
                    </svg>

                    </a>

                    <!-- <a class="link" (click)="onFileDownload(record['DocumentTypeDocumentId'])"
                    href="javascript:void(0);">
                  

                    <svg id="Capa_1" class="view-svg" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g>
                      <path d="m15 91c8.284 0 15-6.716 15-15v-46h46c8.284 0 15-6.716 15-15s-6.716-15-15-15h-61c-8.284 0-15 6.716-15 15v61c0 8.284 6.716 15 15 15z"/><path d="m15 512h61c8.284 0 15-6.716 15-15s-6.716-15-15-15h-46v-46c0-8.284-6.716-15-15-15s-15 6.716-15 15v61c0 8.284 6.716 15 15 15z"/>
                      <path d="m497 91c8.284 0 15-6.716 15-15v-61c0-8.284-6.716-15-15-15h-61c-8.284 0-15 6.716-15 15s6.716 15 15 15h46v46c0 8.284 6.716 15 15 15z"/><path d="m497 421c-8.284 0-15 6.716-15 15v46h-46c-8.284 0-15 6.716-15 15s6.716 15 15 15h61c8.284 0 15-6.716 15-15v-61c0-8.284-6.716-15-15-15z"/>
                      <path d="m256 361c57.897 0 105-47.103 105-105s-47.103-105-105-105-105 47.103-105 105 47.103 105 105 105zm0-180c41.355 0 75 33.645 75 75s-33.645 75-75 75-75-33.645-75-75 33.645-75 75-75z"/><path d="m256 301c24.813 0 45-20.187 45-45s-20.187-45-45-45-45 20.187-45 45 20.187 45 45 45zm0-60c8.271 0 15 6.729 15 15s-6.729 15-15 15-15-6.729-15-15 6.729-15 15-15z"/><path d="m76.023 342.381c50.592 43.433 113.077 78.619 179.977 78.619 66.903 0 129.389-35.19 179.977-78.619 44.516-38.216 72.029-76.003 73.179-77.593 3.793-5.246 3.793-12.332 0-17.577-1.149-1.59-28.663-39.377-73.179-77.593-50.592-43.432-113.077-78.618-179.977-78.618-66.903 0-129.389 35.19-179.977 78.619-44.516 38.216-72.029 76.003-73.179 77.593-3.793 5.246-3.793 12.332 0 17.577 1.15 1.589 28.664 39.377 73.179 77.592zm179.977-221.381c63.673 0 122.116 38.583 159.933 70.951 29.612 25.345 51.609 51.001 62.093 64.044-10.341 12.847-31.897 37.957-61.025 63.071-41.709 35.962-98.941 71.934-161.001 71.934-63.673 0-122.116-38.583-159.933-70.951-29.614-25.347-51.612-51.004-62.093-64.044 10.341-12.847 31.898-37.956 61.026-63.071 41.708-35.962 98.94-71.934 161-71.934z"/></g>
                  </svg>

                  </a> -->
                  </td>

                  <ng-container *ngFor="let header of headers">
                    <td
                      *ngIf="(header.fieldType !== 'Date') && (header.headerName !== 'DocumentId') && (header.headerName !== 'View') && (header.fieldType !== 'Currency')">
                      {{record[header.headerName]}}</td>
                      <td *ngIf="(header.fieldType === 'Currency') && !record[header.headerName]"></td>
                      <td *ngIf="(header.fieldType === 'Currency') && record[header.headerName]">$ {{record[header.headerName]}}</td>
                    <td
                      *ngIf="(header.fieldType == 'Date') && (header.headerName !== 'DocumentId') && (header.headerName !== 'View')">
                      {{record[header.headerName] | date: 'MM/dd/yyyy'}}</td>
                  </ng-container>
                </tr>
              </ng-template>
              <!-- <ng-template pTemplate="emptymessage" let-documentCabinet> -->
              <ng-template pTemplate="emptymessage" let-record *ngIf="!loading && !isInitialEmptyState">
                <tr>
                  <td [attr.colspan]="headers?.length">
                    No records found
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-record *ngIf="isInitialEmptyState">
                <tr *ngIf="!loading">
                  <td [attr.colspan]="headers?.length">
                    No records found
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </ng-container>

        <!-- <div class="table-paginetion" *ngIf="!searchLoader && isDocuware"> -->
        <div class="table-paginetion" *ngIf="!searchLoader && displayPagination && isDocuware">
          <div class="total-record" *ngIf="totalRecordCount"> {{startRecord}} - {{endRecord}} of
            <strong>{{totalRecordCount}}</strong>
          </div>
          <div class="content_detail__pagination cdp" actpage="1">
            <a href="javascript:void(0)" class="cdp_i" (click)="changePage('-1')">prev</a>
            <ng-container *ngFor="let pageNo of pageArray; let i = index">
              <a href="javascript:void(0)" class="cdp_i" (click)="changePage(i+1)"><span>{{i+1}}</span></a>
            </ng-container>
            <a href="javascript:void(0)" class="cdp_i" (click)="changePage('+1')">next</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="loader-outer" *ngIf="loading || customLoader">
    <div class="loader"></div>
  </div>

</div>


<p-dialog styleClass="popop document-viewer-model" [(visible)]="DocumentViewerPopup" [modal]="true" [baseZIndex]="10000" [draggable]="false"
          [resizable]="false">
    <div class="popop-content width-900 text-center h-100 document-preview-pop">
      <div class="document-viewer-box">
        <div class="loader-outer" *ngIf="viewerLoader"><div class="loader"></div></div>
        <div class="pop-content mb-0">
          <h3>Preview Document</h3>
          <!-- <span class="download-btn" (click)="onFileDownload(selectedFileId)">
            <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.7979 15.6562C11.8219 15.6861 11.8525 15.7103 11.8876 15.7269C11.9226 15.7435 11.961 15.7522 12 15.7522C12.039 15.7522 12.0774 15.7435 12.1124 15.7269C12.1474 15.7103 12.1781 15.6861 12.2021 15.6562L15.7957 11.2281C15.9273 11.0656 15.8086 10.825 15.5936 10.825H13.216V0.25C13.216 0.1125 13.1005 0 12.9594 0H11.0342C10.893 0 10.7775 0.1125 10.7775 0.25V10.8219H8.40642C8.19144 10.8219 8.07273 11.0625 8.20428 11.225L11.7979 15.6562ZM23.7433 14.5625H21.8182C21.677 14.5625 21.5615 14.675 21.5615 14.8125V19.625H2.4385V14.8125C2.4385 14.675 2.32299 14.5625 2.18182 14.5625H0.256684C0.115508 14.5625 0 14.675 0 14.8125V21C0 21.5531 0.458823 22 1.02674 22H22.9733C23.5412 22 24 21.5531 24 21V14.8125C24 14.675 23.8845 14.5625 23.7433 14.5625Z"
                    fill="white" />
            </svg>              
            {{fileName}}
          </span> -->
          <div class="image-pdf-viewer">
                <ng-container *ngIf="filePath && fileExtension != 'pdf' && fileExtension != 'png' && fileExtension != 'jpg' && fileExtension != 'jpeg' && fileExtension != 'tiff' ">
                  <p>Browser doesn't support file format</p>
                  <button class="btn-primary mb-0 m-auto" style="margin: 0 auto;" type="button" (click)="onFileDownload(selectedFileId)">Download</button>
                </ng-container>
                <div id="print-section">
                <pdf-viewer *ngIf="isPdf" [src]="base64String"
                    [rotation]="0"
                    [original-size]="false"
                    [show-all]="true"
                    [fit-to-page]="false"
                    [zoom]="1"
                    [stick-to-page]="false"
                    [render-text]="true"
                    [external-link-target]="'blank'"
                    [autoresize]="true"
                    style="">
                </pdf-viewer>
                <img *ngIf="isImage" style="" [src]="transformImg()">
              </div>
              </div>
        </div>
      </div> 
      <ng-container
        *ngIf="fileExtension === 'pdf' || fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'tiff'">
        <!-- <span class="download-btn" (click)="onFileDownload(selectedFileId)">
          <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.7979 15.6562C11.8219 15.6861 11.8525 15.7103 11.8876 15.7269C11.9226 15.7435 11.961 15.7522 12 15.7522C12.039 15.7522 12.0774 15.7435 12.1124 15.7269C12.1474 15.7103 12.1781 15.6861 12.2021 15.6562L15.7957 11.2281C15.9273 11.0656 15.8086 10.825 15.5936 10.825H13.216V0.25C13.216 0.1125 13.1005 0 12.9594 0H11.0342C10.893 0 10.7775 0.1125 10.7775 0.25V10.8219H8.40642C8.19144 10.8219 8.07273 11.0625 8.20428 11.225L11.7979 15.6562ZM23.7433 14.5625H21.8182C21.677 14.5625 21.5615 14.675 21.5615 14.8125V19.625H2.4385V14.8125C2.4385 14.675 2.32299 14.5625 2.18182 14.5625H0.256684C0.115508 14.5625 0 14.675 0 14.8125V21C0 21.5531 0.458823 22 1.02674 22H22.9733C23.5412 22 24 21.5531 24 21V14.8125C24 14.675 23.8845 14.5625 23.7433 14.5625Z"
              fill="white" />
          </svg>
          {{fileName}}
        </span> -->
       
      </ng-container>
      <div class="btns-box document-viewer-box-btn">
        <button class="btn-primary download-file" (click)="onFileDownload(selectedFileId)">
          <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.7979 15.6562C11.8219 15.6861 11.8525 15.7103 11.8876 15.7269C11.9226 15.7435 11.961 15.7522 12 15.7522C12.039 15.7522 12.0774 15.7435 12.1124 15.7269C12.1474 15.7103 12.1781 15.6861 12.2021 15.6562L15.7957 11.2281C15.9273 11.0656 15.8086 10.825 15.5936 10.825H13.216V0.25C13.216 0.1125 13.1005 0 12.9594 0H11.0342C10.893 0 10.7775 0.1125 10.7775 0.25V10.8219H8.40642C8.19144 10.8219 8.07273 11.0625 8.20428 11.225L11.7979 15.6562ZM23.7433 14.5625H21.8182C21.677 14.5625 21.5615 14.675 21.5615 14.8125V19.625H2.4385V14.8125C2.4385 14.675 2.32299 14.5625 2.18182 14.5625H0.256684C0.115508 14.5625 0 14.675 0 14.8125V21C0 21.5531 0.458823 22 1.02674 22H22.9733C23.5412 22 24 21.5531 24 21V14.8125C24 14.675 23.8845 14.5625 23.7433 14.5625Z"
              fill="white" />
          </svg> Download
        </button>
        <button class="btn-primary" *ngIf="isImage" printSectionId="print-section" [printTitle]="fileName"
          ngxPrint>Print</button>
        <button class="btn-primary" *ngIf="!isImage" (click)="onPdfDownload(selectedFileId)">Print</button>
        <!-- <button (click)="onPdfDownload(documentTypeDocumentId)">Print</button> -->
        <button class="btn-secondary ml-16 mb-0" type="button" (click)="onCloseDocumentViewer()">Cancel</button>
      </div>
  </div>
</p-dialog>
<!-- <p-dialog styleClass="popop" [(visible)]="DocumentViewerPopup" [modal]="true" [baseZIndex]="10000" [draggable]="false" [closable]="closeViewer()"
          [resizable]="false">
  <div class="popop-content width-auto">
    <p class="info" *ngIf="!filePath">No document to show</p>

    <div class="doesnt-support" *ngIf="filePath && fileExtension != 'pdf' && fileExtension != 'png' && fileExtension != 'jpg' && fileExtension != 'jpeg'">
      <p>Browser doesn't support file format</p>
    </div>
    <ngx-doc-viewer *ngIf=" filePath && fileExtension == 'pdf' || fileExtension == 'png' || fileExtension == 'jpg' || fileExtension == 'jpeg'"
                    [url]="filePath"
                    viewer="url"
                    style="width:100%;height:50vh;"></ngx-doc-viewer>
   
  </div>
</p-dialog> -->
