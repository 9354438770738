<div class="login-outer">
    <div class="login-outer__block">
        <div class="login-outer__block__box">
            <h1>{{title}}</h1>
            <h2 *ngIf="displayForm">{{h2title}}</h2>

            <div class="alert" [ngClass]="messageClass ? 'success' : 'warning'" *ngIf="displayMessage">
                <p>{{message}}</p>
                <a class="close-popup" href="javascript:void(0);" (click)="closeMessagePopup()"><img width="20"
                        src="assets/img/close-ic.svg"> </a>
            </div>

            <ng-container *ngIf="!displayForm">
                <div class="expired-link">
                    <!-- <h2 class="error">Your session has expired</h2> -->
                    <h2>{{message}}</h2>
                    
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 168 168">
                        <path fill="#c6c6c7" d="M159.426 8.612a3 3 0 1 0-3-3A3 3 0 0 0 159.426 8.612zm0-4.5a1.5 1.5 0 1 1-1.5 1.5A1.5 1.5 0 0 1 159.426 4.112zM94 10a4 4 0 1 0-4-4A4 4 0 0 0 94 10zm0-6a2 2 0 1 1-2 2A2 2 0 0 1 94 4zM165.426 90.612a3 3 0 1 0-3 3A3 3 0 0 0 165.426 90.612zm-3 1.5a1.5 1.5 0 1 1 1.5-1.5A1.5 1.5 0 0 1 162.426 92.112zM31.426 30.612a3 3 0 1 0-3-3A3 3 0 0 0 31.426 30.612zm0-4.5a1.5 1.5 0 1 1-1.5 1.5A1.5 1.5 0 0 1 31.426 26.112zM7.426 25.612a2 2 0 1 0-2-2A2 2 0 0 0 7.426 25.612zm0-3a1 1 0 1 1-1 1A1 1 0 0 1 7.426 22.612zM150.721 148.388H84.959a63.984 63.984 0 0 0 63.036-63.925c0-.017.005-.033.005-.05s0-.035-.005-.053A63.993 63.993 0 0 0 84.1 20.423c-.035 0-.068-.01-.1-.01s-.068.009-.1.01A63.993 63.993 0 0 0 20.005 84.359c0 .018-.005.035-.005.053s0 .033.005.05a63.984 63.984 0 0 0 63.036 63.925H17.279a2.017 2.017 0 1 0 0 4H150.721a2.017 2.017 0 1 0 0-4zM82 136.826v7.535A60.05 60.05 0 0 1 24.055 86.412h7.532a2 2 0 0 0 0-4H24.055A60.05 60.05 0 0 1 82 24.471V32a2 2 0 0 0 4 0V24.471a60.05 60.05 0 0 1 57.945 57.941h-7.531a2 2 0 0 0 0 4h7.531A60.05 60.05 0 0 1 86 144.362v-7.535a2 2 0 0 0-4 0z"/>
                        <circle cx="2" cy="150.388" r="2" fill="#c6c6c7"/>
                        <path fill="#c6c6c7" d="M160 148.388h-3a2 2 0 0 0 0 4h3a2 2 0 0 0 0-4zM11 148.388H8a2 2 0 0 0 0 4h3a2 2 0 0 0 0-4z"/>
                        <circle cx="166" cy="150.388" r="2" fill="#c6c6c7"/>
                        <path fill="#c6c6c7" d="M131.219,114.745c1.025-1.778.866-4.088-.469-6.393L89.271,41.793c-1.3-2.23-3.221-3.509-5.269-3.51h0a6.259,6.259,0,0,0-5.255,3.484L37.217,108.408c-1.3,2.247-1.462,4.557-.437,6.335s3.114,2.806,5.726,2.806H125.5C128.105,117.549,130.191,116.528,131.219,114.745Zm-90.973-2a2.513,2.513,0,0,1,.4-2.277L82.173,43.828c.561-.967,1.245-1.545,1.828-1.545s1.266.578,1.826,1.545l41.494,66.584a2.573,2.573,0,0,1,.433,2.335,2.6,2.6,0,0,1-2.258.8H42.505A2.6,2.6,0,0,1,40.246,112.746Z"/>
                        <polygon fill="#c6c6c7" points="15.18 48.519 17.282 45.755 15.955 45.003 14.606 48.099 14.561 48.099 13.19 45.025 11.84 45.799 13.92 48.498 13.92 48.542 10.667 48.121 10.667 49.625 13.941 49.206 13.941 49.25 11.84 51.947 13.1 52.744 14.539 49.625 14.583 49.625 15.91 52.722 17.304 51.926 15.18 49.271 15.18 49.227 18.519 49.625 18.519 48.121 15.18 48.564 15.18 48.519"/>
                        <polygon fill="#c6c6c7" points="42.552 10.497 44.833 7.497 43.392 6.681 41.929 10.041 41.88 10.041 40.392 6.705 38.927 7.545 41.184 10.474 41.184 10.521 37.654 10.065 37.654 11.697 41.207 11.242 41.207 11.29 38.927 14.217 40.295 15.082 41.856 11.697 41.903 11.697 43.343 15.057 44.856 14.194 42.552 11.313 42.552 11.265 46.175 11.697 46.175 10.065 42.552 10.545 42.552 10.497"/>
                        <polygon fill="#c6c6c7" points="71.202 12.678 70.346 13.777 70.859 14.102 71.446 12.831 71.463 12.831 72.004 14.093 72.572 13.769 71.707 12.687 71.707 12.669 73.067 12.831 73.067 12.219 71.707 12.399 71.707 12.381 72.563 11.255 72.022 10.948 71.473 12.21 71.454 12.21 70.896 10.957 70.346 11.273 71.193 12.372 71.193 12.39 69.868 12.219 69.868 12.831 71.202 12.66 71.202 12.678"/>
                        <polygon fill="#c6c6c7" points="161.79 48.586 161.79 47.02 158.313 47.481 158.313 47.435 160.501 44.556 159.119 43.773 157.714 46.997 157.668 46.997 156.24 43.796 154.834 44.602 157 47.412 157 47.458 153.613 47.02 153.613 48.586 157.023 48.149 157.023 48.195 154.834 51.004 156.147 51.834 157.645 48.586 157.69 48.586 159.072 51.811 160.524 50.982 158.313 48.218 158.313 48.172 161.79 48.586"/>
                        <polygon fill="#c6c6c7" points="139.313 30.435 141.501 27.556 140.119 26.773 138.714 29.997 138.668 29.997 137.24 26.796 135.834 27.602 138 30.412 138 30.458 134.613 30.02 134.613 31.586 138.023 31.149 138.023 31.195 135.834 34.004 137.147 34.834 138.645 31.586 138.69 31.586 140.072 34.811 141.524 33.982 139.313 31.218 139.313 31.172 142.79 31.586 142.79 30.02 139.313 30.481 139.313 30.435"/>
                        <polygon fill="#c6c6c7" points="132.84 9.604 134.672 9.822 134.672 8.997 132.84 9.24 132.84 9.215 133.993 7.698 133.265 7.285 132.524 8.984 132.5 8.984 131.747 7.297 131.006 7.722 132.148 9.203 132.148 9.228 130.363 8.997 130.363 9.822 132.16 9.592 132.16 9.616 131.006 11.097 131.698 11.534 132.488 9.822 132.511 9.822 133.24 11.522 134.005 11.085 132.84 9.628 132.84 9.604"/>
                        <path fill="#c6c6c7" d="M118.154 156h-8.308a2.006 2.006 0 0 0 0 4h8.308a2.006 2.006 0 0 0 0-4zM58.154 156H49.846a2.006 2.006 0 0 0 0 4h8.308a2.006 2.006 0 0 0 0-4zM104 156H64a2 2 0 0 0 0 4H79.94v2H72a2 2 0 0 0 0 4H97a2 2 0 0 0 0-4H88.06v-2H104a2 2 0 0 0 0-4zM84 88.291a2 2 0 0 0 2-2V61.542a2 2 0 1 0-4 0V86.291A2 2 0 0 0 84 88.291z"/>
                        <circle cx="84" cy="96.291" r="4" fill="#c6c6c7"/>
                    </svg> -->
                    
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                        <rect width="256" height="256" fill="none"/>
                        <line x1="128" x2="128" y1="104" y2="144" fill="none" stroke="#f42525" stroke-linecap="round" stroke-linejoin="round" stroke-width="10"/>
                        <path fill="none" stroke="#f42525" stroke-linecap="round" stroke-linejoin="round" stroke-width="10" d="M114.15243,39.98472,26.17616,191.977a16.00005,16.00005,0,0,0,13.84762,24.01535H215.97625A16,16,0,0,0,229.82386,191.977L141.84757,39.98472A16,16,0,0,0,114.15243,39.98472Z"/>
                        <circle cx="128" cy="180" r="8" fill="#f42525"/>
                    </svg>

                    <div class="forgot-password">
                        <a href="javascript:void(0);" routerLinkActive="active" routerLink="/forgot-password" >Forgot Password?</a>
                    </div>
                    <!-- {{message}} -->
                </div>
            </ng-container>

            <ng-container *ngIf="displayForm">
            
                <form [formGroup]="form" autocomplete="off">
                    <div class="form__group">
                        <label class="form__label">New Password

                            <div class="password-info reset-pass">
                                <img class="password-info__img" src="assets/img/info-ic.svg" alt="info" width="22">
                                <ul class="password-info__box">
                                    <li>Password Suggestions</li>
                                    <li>At least one uppercase letter</li>
                                    <li>At least one lowercase letter</li>
                                    <li>At least one number</li>
                                    <li>At least one special character</li>
                                    <li>Minimum 8 characters long</li>
                                </ul>
                            </div>
                        </label>
                        <div class="login-inputpass">
                            <input type="password" class="form__control" formControlName="newPassword">
                            <span class="error"
                                *ngIf="hasError(formFields.newPassword, 'required')">{{validationMessages.password.required}}</span>
                            <span class="error"
                                *ngIf="hasError(formFields.newPassword, 'minLength')">{{validationMessages.password.minlength}}</span>
                            <span class="error"
                                *ngIf="hasError(formFields.newPassword, 'validStrongPassword')">{{validationMessages.password.invalid}}</span>
                        </div>


                    </div>
                    <div class="form__group">
                        <label class="form__label">Confirm Password</label>
                        <div class="login-inputpass">
                            <input class="form__control" type="password" formControlName="confirmPassword">
                        </div>
                        <span class="error"
                            *ngIf="hasError(formFields.confirmPassword, 'required')">{{validationMessages.confirmPassword.required}}</span>
                        <span class="error"
                            *ngIf="form.hasError('validPasswordMatch')">{{validationMessages.confirmPassword.validPasswordMatch}}</span>
                    </div>
                    <div class="form__group mb-0 center">
                        <button class="btn-primary" type="submit" (click)="onSubmit()">Submit</button>
                    </div>
                </form>
            </ng-container>
        </div>
    </div>
</div>