import { DocumentTypeDocumentMetadataResponseModel } from "./document-type-document-metadata-response";

export class DocumentTypeDocumentResponseModel {
    DocumentTypeDocumentId: number;
    CompanyId: number;
    DocumentTypeId: number;
    DocumentPath: string;
    IsPublic: boolean;
    LastModifiedBy: number;
    DateTimeCreated: Date;
    DateTimeLastModified: Date;
    ActualDocumentName: string;
    DocumentTypeDocumentMetadatas: DocumentTypeDocumentMetadataResponseModel[];
}

export class DocumentDisplayModel {
    DocumentTypeInboxId: number;
    DocumentTypeId: number;
    DocumentPath: string;
    OCRData: string;
    FileSize: string;
    IsPublic: boolean;
    ActualDocumentName: string;
}

export class SPDocumentsByDocType {
    ID: number;
    DocumentId: number;
    FileName: string;
    FileSize: string;
    UploadedOn: Date;
    Metadata: boolean;
    OCRStatus: string;
    FailReason: string;
}

export class DocumentTypeDocumentResponse {
    DocumentTypeDocumentId: number;
    CompanyId: number;
    DocumentTypeId: number;
    DocumentPath: string;
    IsPublic: boolean;
    LastModifiedBy: number;
    DateTimeCreated: Date;
    DateTimeLastModified: Date;
}

export class DocumentTypeDocumentModelForSearch {
    // Documents: DocumentTypeDocumentResponseModel[];
    Documents: DocumentDisplayModel[];
    BackupSearchRecords: any[];
    SearchInput: SearchInputModel[];
}

export class SearchInputModel {
    DocumentTypeMetadataId: number;
    DisplayAs: string;
    FieldName: string;
    FieldType: string;
    Sequence: number;
    FilteredValues: LabelValueModel[];
    Values: LabelValueModel[];
}

export class LabelValueModel {
    label: string;
    value: string;
}

export class SPFullTextModel 
{ 
    // DocumentTypeDocumentId: number;
    DocumentTypeInboxId: number;
}

export class FilterValueModel {
    label: string;
    value: string;
    FieldName: string;
}
