import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { WebStorageService } from '../../services/web-storage.service';

interface Company {
  name: string,
  code: string
}

@Component({
  selector: 'app-header-customer',
  templateUrl: './header-customer.component.html',
  styleUrls: ['./header-customer.component.sass']
})
export class HeaderCustomerComponent implements OnInit {

  organization: Company[];
  selectedCity: Company;
  status: boolean;
  companyName: string;
  companyId: number;
  isCompanyPage: boolean;
  isDocuware: boolean;
  constructor(private webStorageService: WebStorageService, private router: Router) {
    const currentToken = this.webStorageService.getCurrentUserToken();
    if (currentToken) {
      this.companyName = currentToken.companyName;
      this.companyId = currentToken.companyId;
      this.isDocuware = currentToken.isDocuware;
    }
    this.organization = [
      { name: this.companyName, code: this.companyName },
     
    ];
  }

  ngOnInit(): void {

    $(document).on('click', function () {
      $('.custome-dropdownmenu').next().removeClass('active');
      $('.custome-dropdownmenu').removeClass('active');      
    });

    if ($('.custome-click').length) {
      const dropdown = $('.custome-click');
      dropdown.on('click', function (e) {
        e.stopPropagation();
        if (!dropdown.next().hasClass('active')) {
          dropdown.next().addClass('active');
        } else {
          dropdown.next().removeClass('active');
        }
      });      
    }

    if(this.router.url !== '/company' && this.router.url !== '/my-profile' && this.router.url !== '/change-password')
    {
      this.isCompanyPage = false;
    } else {
      this.isCompanyPage = true;
    }

  }
}
