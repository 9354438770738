import { DocumentCabinetResponseModel } from "./document-cabinet-response";
import { DocumentTypeInboxResponseModel } from "./document-type-inbox-response";
import { DocumentTypeMetadataResponseModel } from "./document-type-metadata-response";
import { GroupPermissionsDisplayResponseModel, GroupResponseModel } from "./group-response";
import { UserInfoResponseModel, UserPermissionDisplayResponseModel } from "./userInfo-response";

export class DocumentTypeResponseModel {
  DocumentTypeId: number;
  DocumentCabinetId: number;
  DocuwareCabinetId: string;
  // DocumentType1: string;
  DocumentTypeName: string;
  DocumentTypeURL: string;
  AreDocuments: boolean;
  IsVisibleToCustomer:boolean;
  HeaderBGColor: string;
  LogoImage: string;
  PageTitleColor: string;
  DocumentGroupTitleColor: string;
  TableHeaderLabelColor: string;
  TableHeaderBGColor: string;
  // ModifiedBy: string;
  LastModifiedBy: number;
  DateTimeCreated: Date;
  DateTimeLastModified: Date;
  DocumentCabinetName: string;  
  AreDocumentsPublic:  boolean;

  DocumentCabinet: DocumentCabinetResponseModel;
  DocumentTypeMetadatas: Array<DocumentTypeMetadataResponseModel>;
  DocumentTypeInboxes: Array<DocumentTypeInboxResponseModel>;

  isFull: boolean;
  isView: boolean;
  isCreate: boolean;
  isEdit: boolean;
  isDelete: boolean;

  SearchDialogId: string;
  SearchDialogName: string;
  SearchDialogDetail: Array<SearchDialogDetail>;
}

export class DocumentTypeListResponseModel {
  DocumentTypeId: number;
  DocumentCabinetId: number;
  DocumentTypeName: string;
  DocumentTypeURL: string;
  AreDocuments: boolean;
  IsVisibleToCustomer:boolean;
  HeaderBGColor: string;
  LogoImage: string;
  PageTitleColor: string;
  DocumentGroupTitleColor: string;
  TableHeaderLabelColor: string;
  TableHeaderBGColor: string;
  LastModifiedBy: number;
  DateTimeCreated: Date;
  DateTimeLastModified: Date;
  DocumentCabinetName: string;
  
  AreDocumentsPublic:  boolean;
}

export class SearchDialogDetail {
  CabinetSearchDialogId: string;
  CabinetSearchDialogName: string;
  DocumentTypeMetadatas: Array<DocumentTypeMetadataResponseModel>;
}

export class DocumentTypeUserGroupResponseModel {
  DocumentTypeId: number;
  DocumentTypeName: string;  
  DocumentCabinetName: string;
  GroupCount: number;
  UserCount: number;
}

export class DocumentTypePermissionsResponseModel {
  DocumentTypeId: number;
  DocumentTypeName: string;
  Users: Array<UserInfoResponseModel>;
  Groups: Array<GroupResponseModel>;
}

export class DocumentTypePermissionDisplayModel {
  DocumentTypeId: number;
  DocumentTypeName: string;
  Users: Array<UserPermissionDisplayResponseModel>;
  Groups: Array<GroupPermissionsDisplayResponseModel>;
}

export class SPDocumentTypesByCompanyIdResponseModel {
  DocumentCabinetId: number;
  DocumentTypeId:  number;
  DocumentCabinetName: string;
  DocumentTypeName: string;
  AreDocumentsPublic:  Boolean;
  DateTimeCreated: Date;  
  IsVisibleToCustomer: boolean
  DocumentTypeURL: string;

  isFull: boolean;
  isView: boolean;
  isCreate: boolean;
  isEdit: boolean;
  isDelete: boolean;
}

export class DocumentTypeWithDocNumberResponseModel {
  DocumentCabinetId: number;
  DocumentTypeId: number;
  DocumentTypeName: string;
  DocumentCountForInboxModule: number;
}