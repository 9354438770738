export class UserInfoRequestModel {
  CompanyId: number;
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  password: string;
  isActive: boolean;
  isDeleted: boolean;
  isForgotPassword: boolean;
  IsLinkExpired: boolean;
  Role: string;  
  LastAccessDate: Date;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;

}
