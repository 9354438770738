import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from '../shared/base.component';
import { Subject } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import { CustomValidator } from '../shared/custom-validator';
import { Constants } from '../shared/constants';
import { WebStorageService } from '../services/web-storage.service';
import { ChangePasswordModel } from '../models/response/forgot-password';
import { takeUntil, finalize } from 'rxjs/operators';
import { ResultOfT } from '../models/response/result';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UserRole } from '../shared/enum';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.sass']
})
export class ChangePasswordComponent extends BaseComponent implements OnInit, OnDestroy {

  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();

  currentUserToken: any;
  userId: number;
  displayMessage: boolean = false;
  message: string ='';
  messageClass: boolean;

  formFields =
  {
    oldPassword: 'oldPassword',
    newPassword: 'newPassword',
    confirmPassword: 'confirmPassword'
  };

  validationMessages = {
    oldPassword: {
      required: 'Old password is required',
      // minlength: 'Password should be at least 8 characters',
      // invalid: 'Enter valid password'
    },
    password: {
      required: 'New password is required',
      minlength: 'New Password should be at least 8 characters',
      invalid: 'Enter valid new password'
    },
    confirmPassword: {
      required: 'Confirm password is required',
      invalid: 'Enter valid confirm password',
      minlength: 'Confirm password should be at least 8 characters',
      validPasswordMatch: 'New password and confirm password don\'t match'
    }
  };
  isCustomer: boolean;

  constructor(private formBuilder: FormBuilder, private userService: UserService,
    private webStorageService: WebStorageService, private router: Router) {
    super();
  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  ngOnInit(): void {
    this.buildForm();
    this.currentUserToken = this.webStorageService.getCurrentUserToken();

    this.userId = this.currentUserToken.userId;
    this.isCustomer = this.currentUserToken.role === UserRole.enumCustomer ? true : false;
  }

  closeMessagePopup(){
    this.displayMessage = false;
  }

  onSubmit() {
// debugger;
    if(this.form.invalid){
      this.markFormAsTouched();
      return;
    }

    const reqModel = new ChangePasswordModel()
    reqModel.userId = this.userId;
    reqModel.oldPassword = this.form.get(this.formFields.oldPassword).value;
    reqModel.newPassword = this.form.get(this.formFields.newPassword).value;

    this.userService.ChangePassword(reqModel)
    .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
    .subscribe((result: ResultOfT<boolean>) => {
      this.processResult<boolean>(result, async () => {
        // debugger;
        if (result.IsSuccess && result?.ResponseModel == true) {

          this.timeoutFunct("Password updated successfully", true);
          this.form.reset();

        } else {
          this.timeoutFunct(result.Message, false);
        }
      });
    }, (httpResponseError) => {
      this.timeoutFunct(httpResponseError.message, false);
    });

  }


  timeoutFunct(msg,  isSuccess: boolean) {
    this.displayMessage =true;
    this.messageClass = isSuccess;
    this.message = msg;
    setTimeout(() => {
      this.message = '';
      this.displayMessage = false;
    }, environment.messageLifeSpan);
  }


  onReset() {
    this.form.reset();
  }

  onBack() {
    // if(this.currentUserToken.role === UserRole.enumCustomer) {
    //   var url = '/company/' + this.currentUserToken.companyId +'/document-cabinet';
    //   this.router.navigate([url]);
    // } else {
    //   this.router.navigate(['/company']);
    // }
    if(this.currentUserToken.role === UserRole.enumAdmin) {      
      this.router.navigate(['/company']);
    } else {      
      // var url = '/company/' + this.currentUserToken.companyId +'/document-cabinet';
      var url = '/company/' + this.currentUserToken.companyId +'/configuration/document-filing-cabinets/document-cabinets';
      this.router.navigate([url]);
    }
  }

  private buildForm() {
    const formGroup = this.formBuilder.group({});

    formGroup.addControl(this.formFields.oldPassword, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace],
      updateOn: Constants.formControlUpdateStrategy.blur }));

    formGroup.addControl(this.formFields.newPassword, this.formBuilder.control('',
    { validators: [Validators.required, Validators.minLength(8), CustomValidator.whitespace,CustomValidator.passwordPattern],
      updateOn: Constants.formControlUpdateStrategy.blur }));

    formGroup.addControl(this.formFields.confirmPassword, this.formBuilder.control('',
    { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.passwordPattern],
              updateOn: Constants.formControlUpdateStrategy.blur }));

    formGroup.setValidators(CustomValidator.passwordMatch('newPassword', 'confirmPassword'));

    this.form = formGroup;
}


}
