<div class="main-outer">
  <app-header *ngIf="!isCustomer"></app-header>
  <app-header-customer *ngIf="isCustomer"></app-header-customer>
    <div class="municipality-accounts">
        <div class="container">
            <div class="title-block">              

                  <h1>
                    <a class="back-btns" (click)="onBack()" href="javascript:void(0);">
                      <svg width="18" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 8.2L7.98523 1V5C19.913 5 21.2891 12.7424 20.9578 17C20.4569 14.852 20.2243 11.4 7.98523 11.4V15.4L1 8.2Z" stroke="#414141" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        </a> 
                      My Profile
                  </h1>

                
            </div>
            <div class="alert" [ngClass]="messageClass ? 'success' : 'warning'" *ngIf="displayMessage">
              <p>{{message}}</p>
              <a class="close-popup" href="javascript:void(0);" (click)="closeMessagePopup()"><img width="20" src="assets/img/close-ic.svg"> </a>
            </div>


            <form [formGroup]="form" autocomplete="off">
              <div class="general-info">
                <div class="card-block">
                  <div class="form__group">
                    <label class="form__label">First Name</label>
                    <input type="text" class="form__control" formControlName="firstName" >
                    <span *ngIf="hasError(formFields.firstName,'required')" class="error">{{validationMessages.firstName.required}}</span>
                    <span *ngIf="hasError(formFields.firstName,'validCharacters')" class="error">{{validationMessages.firstName.validCharacters}}</span>
                  </div>

                  <div class="form__group">
                    <label class="form__label">Last Name</label>
                    <input type="text" class="form__control" formControlName="lastName">
                    <span *ngIf="hasError(formFields.lastName,'required')" class="error">{{validationMessages.lastName.required}}</span>
                    <span *ngIf="hasError(formFields.lastName,'validCharacters')" class="error">{{validationMessages.lastName.validCharacters}}</span>
                  </div>

                  <div class="form__group">
                    <label class="form__label">Email</label>
                    <input type="text" class="form__control" formControlName="email" disabled>
                    <span *ngIf="hasError(formFields.email,'required')" class="error">{{validationMessages.email.required}}</span>
                    <span *ngIf="hasError(formFields.email,'email')" class="error">{{validationMessages.email.email}}</span>
                  </div>
                </div>

                <div class="button-block">
                  <button class="btn-primary" type="submit" (click)="onSubmit()">Update</button>
                </div>
              </div>
            </form>
        </div>
    </div>
</div>
