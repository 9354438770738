import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { ConfigurationRoutingModule } from './configuration-routing.module';
import { ConfigurationMenuComponent } from './configuration-menu/configuration-menu.component';
import { ConfigurationComponent } from './configuration.component';
// import { HeaderComponent } from '../../components/header/header.component';
//import { DropdownModule } from 'primeng/dropdown';
//import { DropdownMenuComponent } from '../../components/dropdown-menu/dropdown-menu.component';
//import { OverlayPanelModule } from 'primeng/overlaypanel';
import {DialogModule} from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import {MultiSelectModule} from 'primeng/multiselect';
import { SharedModule } from '../../shared/shared.module';
// import { DeletePopupComponent } from '../../components/delete-popup/delete-popup.component';


@NgModule({
  declarations: [ConfigurationMenuComponent, ConfigurationComponent],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    ConfigurationRoutingModule,
    //DropdownModule,
    FormsModule,
    //OverlayPanelModule,
    DialogModule,
    FormsModule,
    CalendarModule,
    InputNumberModule,
    MultiSelectModule,
    SharedModule
  ],
  exports: [
    ConfigurationComponent, 
    DialogModule,
    ConfigurationMenuComponent,
    MultiSelectModule
   
  ]
})
export class ConfigurationModule { }
