import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from '../shared/base.component';
import { UserService } from '../services/user.service';
import { WebStorageService } from '../services/web-storage.service';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidator } from '../shared/custom-validator';
import { Constants } from '../shared/constants';
import { Subject } from 'rxjs';
import { UserInfoRequestModel } from '../models/request/userInfo-request';
import { takeUntil, finalize } from 'rxjs/operators';
import { ResultOfT } from '../models/response/result';
import { UserAuthResponseModel } from '../models/response/access-token-response';
import { UserInfoResponseModel } from '../models/response/userInfo-response';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { UserRole } from '../shared/enum';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.sass']
})
export class MyProfileComponent extends BaseComponent implements OnInit, OnDestroy {

  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();

  currentUserToken: any;
  displayMessage: boolean = false;
  message: string ='';
  messageClass: boolean;
  isAdminHeader: boolean = false;
  products1:any[];

  formFields =
  {
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
  };

  validationMessages = {
    firstName: {
      required: 'First name is required',
      validCharacters: 'First name is invalid'
    },
    lastName: {
      required: 'Last name is required',
      validCharacters: 'Last namer is invalid'
    },
    email: {
      required: 'Email address is required',
      email: 'Email address is invalid'
    }
};
  isCustomer: boolean;

  constructor(private userService: UserService, private webStorageService: WebStorageService, private router: Router,
    private formBuilder: FormBuilder) {
    super();

    this.currentUserToken = this.webStorageService.getCurrentUserToken();

    this.isCustomer = this.currentUserToken.role === UserRole.enumCustomer ? true : false;
  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  ngOnInit(): void {
    this.currentUserToken = [];

    this.buildForm();
    this.onReset();
  }

  onSubmit(){
    // debugger;
    if(this.form.invalid){
      this.markFormAsTouched();
      return;
    }

    const reqModel = new UserInfoRequestModel;
    reqModel.firstName = this.form.get(this.formFields.firstName).value;
    reqModel.lastName = this.form.get(this.formFields.lastName).value;
    reqModel.email = this.form.get(this.formFields.email).value;
    reqModel.userId = this.currentUserToken.userId;


    this.userService.updateProfile(reqModel)
    .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
    .subscribe((result: ResultOfT<UserInfoResponseModel>) => {
      this.processResult<UserInfoResponseModel>(result, async () => {
// debugger;
        
        if (result.IsSuccess) {

          var token = this.webStorageService.getCurrentUserToken().token;

          this.webStorageService.deleteCurrentUserToken();

          const authToken = new UserAuthResponseModel();
          authToken.companyId = result?.ResponseModel?.CompanyId;
          authToken.companyName = result?.ResponseModel?.CompanyName;
          authToken.groupId = result?.ResponseModel?.GroupId;
          authToken.userId = result?.ResponseModel?.UserId;
          authToken.role = result?.ResponseModel?.Role;
          authToken.email = result?.ResponseModel?.Email;
          authToken.userName = result?.ResponseModel?.FirstName + " " + result?.ResponseModel?.LastName;
          authToken.userFirstName = result?.ResponseModel?.FirstName;
          authToken.userLastName = result?.ResponseModel?.LastName;
          authToken.token = token;
          this.webStorageService.storeCurrentUserToken(authToken);

          this.timeoutFunct('Profile updated', true);

        } else {
          this.timeoutFunct(result.Message, false);
        }
      });
    }, (httpResponseError) => {
      this.timeoutFunct(httpResponseError.message, false);
    });
  }

  timeoutFunct(msg, isSuccess: boolean) {
    this.displayMessage =true;
    this.messageClass = isSuccess;
    this.message = msg;
    setTimeout(() => {
      this.message = '';
      this.displayMessage = false;
    }, environment.messageLifeSpan);
  }

  closeMessagePopup(){
    this.displayMessage = false;
  }

  onReset(){
    // debugger;
    this.currentUserToken = this.webStorageService.getCurrentUserToken();
    this.form.get(this.formFields.email).setValue(this.currentUserToken.email);
    // var username = this.currentUserToken.userName.split(" ", 2);
    // this.form.get(this.formFields.firstName).setValue(username[0]);
    // this.form.get(this.formFields.lastName).setValue(username[1]);
    this.form.get(this.formFields.firstName).setValue(this.currentUserToken.userFirstName);
    this.form.get(this.formFields.lastName).setValue(this.currentUserToken.userLastName);
  }

  onBack(){
    // if(this.currentUserToken.role === UserRole.enumCustomer) {
    //   var url = '/company/' + this.currentUserToken.companyId +'/document-cabinet';
    //   this.router.navigate([url]);
    // } else {
    //   this.router.navigate(['/company']);
    // }
    if(this.currentUserToken.role === UserRole.enumAdmin) {      
      this.router.navigate(['/company']);
    } else {      
      // var url = '/company/' + this.currentUserToken.companyId +'/document-cabinet';
      var url = '/company/' + this.currentUserToken.companyId +'/configuration/document-filing-cabinets/document-cabinets';
      this.router.navigate([url]);
    }
  }

  private buildForm(){
    const formGroup = this.formBuilder.group({});

    formGroup.addControl(this.formFields.firstName, this.formBuilder.control("",
    { validators: [Validators.required, CustomValidator.onlyCharacters],
      updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.lastName, this.formBuilder.control("",
    { validators: [Validators.required, CustomValidator.onlyCharacters],
      updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.email, this.formBuilder.control("",
    { validators: [Validators.required, CustomValidator.email],
      updateOn: Constants.formControlUpdateStrategy.blur }));

      this.form = formGroup;
  }

}
