<!-- <p>company-manage works!</p> -->
<div class="document-cabinet-list">
    <div class="alert" [ngClass]="messageClass ? 'success' : 'warning'" *ngIf="displayMessage">
        <p>{{message}}</p>
        <a class="close-popup" href="javascript:void(0);" (click)="closeMessagePopup()"><img width="20"
                src="assets/img/close-ic.svg"> </a>
    </div>
    <form class="form" [formGroup]="form" autocomplete="off">
    <div class="title-block model-heading">
        <h1 class="popup-heading" *ngIf="companyId == 0">Add Customer</h1>
        <h1 class="popup-heading" *ngIf="companyId > 0">Edit Customer</h1>
        <div class="switchbutton">
            <span class="switch-outer">
                <label class="switch">
                    <input type="checkbox" [(ngModel)]="isActive" [ngModelOptions]="{standalone: true}"
                        checked>
                    <span class="slider round"></span>
                </label>
                {{isActive ? 'Active' : 'Inactive'}}
            </span>

        </div>
    </div>

    <div class="add-customer-popup-inner">
        <div class="custom-scroll">
            <div class="general-info">
                <h2 class="pop-sub-heading">Organization Information</h2>
                <div class="card-block">
                    <div class="form__group">
                        <label class="form__label">Organization Name</label>
                        <input type="text" class="form__control" formControlName="customerOrganizationName">
                        <span class="error"
                            *ngIf="hasError(formFields.customerOrganizationName, 'required')">{{validationMessages.customerOrganizationName.required}}</span>
                        <span class="error"
                            *ngIf="hasError(formFields.customerOrganizationName, 'validCharacters')">{{validationMessages.customerOrganizationName.validCharacters}}</span>
                    </div>

                    <div class="form__group">
                        <label class="form__label">Website URL</label>
                        <input type="text" class="form__control" formControlName="websiteURL">
                        <span class="error"
                            *ngIf="hasError(formFields.websiteURL, 'required') || hasError(formFields.websiteURL, 'whitespace')">{{validationMessages.websiteURL.required}}</span>
                    </div>
                </div>
            </div>
            <div class="general-info">
                <h2 class="pop-sub-heading">Organization Contact Information</h2>
                <div class="card-block">
                    <div class="form__group">
                        <label class="form__label">First Name</label>
                        <input type="text" class="form__control" formControlName="contactFirstName">
                        <span class="error"
                            *ngIf="hasError(formFields.contactFirstName, 'required') ">{{validationMessages.contactFirstName.required}}</span>
                        <span class="error"
                            *ngIf="hasError(formFields.contactFirstName, 'validCharacters')">{{validationMessages.contactFirstName.validCharacters}}</span>
                    </div>

                    <div class="form__group">
                        <label class="form__label">Last Name</label>
                        <input type="text" class="form__control" formControlName="contactLastName">
                        <span class="error"
                            *ngIf="hasError(formFields.contactLastName, 'required') ">{{validationMessages.contactLastName.required}}</span>
                        <span class="error"
                            *ngIf="hasError(formFields.contactLastName, 'validCharacters')">{{validationMessages.contactLastName.validCharacters}}</span>
                    </div>

                    <div class="form__group">
                        <label class="form__label">Email</label>
                        <input type="text" class="form__control" formControlName="contactEmail">
                        <span class="error"
                            *ngIf="hasError(formFields.contactEmail, 'required') ">{{validationMessages.contactEmail.required}}</span>
                        <span class="error"
                            *ngIf="hasError(formFields.contactEmail, 'email')">{{validationMessages.contactEmail.email}}</span>
                    </div>
                    <div class="form__group">
                        <label class="form__label">Phone</label>
                        <!-- <input type="text" class="form__control"> -->
                        <p-inputMask class="form__phone" formControlName="contactPhone" mask="(999) 999-9999">
                        </p-inputMask>
                        <span class="error"
                            *ngIf="hasError(formFields.contactPhone, 'required') ">{{validationMessages.contactPhone.required}}</span>
                    </div>
                </div>
            </div>
            <div class="general-info">
                <h2 class="pop-sub-heading">Integration</h2>

                <div class="card-block">
                    <div class="form__group">
                        <label class="form__label">Document Integration</label>                

                        <p-dropdown class="select-dropdown topOpen"
                    
                        formControlName="documentIntegration" [options]="databaseOptions" optionLabel="title" optionValue="title" 
                                placeholder="Select" (onChange)="onSelectChange($event)" ></p-dropdown>
                        <span class="error"
                            *ngIf="hasError(formFields.documentIntegration, 'required') ">{{validationMessages.documentIntegration.required}}</span>

                        <!-- <select class="form__control">
                            <option>Select</option>
                            <option>App Database</option>
                            <option>DocuWare</option>
                        </select> -->
                    </div>
                    
                    <div class="form__group" *ngIf="!isSelectedDbDocuware && showStorageInput">
                        <label class="form__label">Storage (In GB)</label>
                        <input type="text" class="form__control" formControlName="allowedStorage">
                        <span class="error"
                            *ngIf="hasError(formFields.allowedStorage, 'required') ">{{validationMessages.allowedStorage.required}}</span>
                        <span class="error"
                            *ngIf="hasError(formFields.allowedStorage, 'validNumber')">{{validationMessages.allowedStorage.validNumber}}</span>
                    </div>

                    <div class="form__group mb-0">
                    </div>

                    <div class="form__group" *ngIf="isSelectedDbDocuware">
                        <label class="form__label">Organization Name</label>
                        <input type="text" class="form__control" formControlName="organizarionName">
                        <span class="error"
                            *ngIf="hasError(formFields.organizationName, 'required')">{{validationMessages.organizationName.required}}</span>
                    </div>

                    <div class="form__group"  *ngIf="isSelectedDbDocuware">
                        <label class="form__label">URL <small>(https://xyx.docuware.cloud)</small></label>
                        <input type="text" class="form__control" formControlName="serverURL">
                        <span class="error"
                            *ngIf="hasError(formFields.serverURL, 'required')">{{validationMessages.serverURL.required}}</span>
                    </div>

                    <div class="form__group"  *ngIf="isSelectedDbDocuware">
                        <label class="form__label">User Name</label>
                        <input type="text" class="form__control" formControlName="userName">
                        <span class="error"
                            *ngIf="hasError(formFields.userName, 'required') ">{{validationMessages.userName.required}}</span>
                        <!--<span class="error"
                            *ngIf="hasError(formFields.userName, 'validCharacters') ">{{validationMessages.userName.validCharacters}}</span>-->
                        <!-- <span class="error" *ngIf="hasError(formFields.userName, 'email')">{{validationMessages.userName.email}}</span> -->
                    </div>

                    <div class="form__group"  *ngIf="isSelectedDbDocuware">
                        <label class="form__label">Password</label>
                        <input type="password" class="form__control" formControlName="password">
                        <span class="error"
                            *ngIf="hasError(formFields.password, 'required') ">{{validationMessages.password.required}}</span>
                    </div>

                    <div class="form__group"  *ngIf="isSelectedDbDocuware">
                        <label class="form__label">Passphrase</label>
                        <input type="text" class="form__control" formControlName="passphrase">
                        <span class="error"
                            *ngIf="hasError(formFields.passphrase, 'required') ">{{validationMessages.passphrase.required}}</span>
                    </div>

                    <div class="test-connect-btn"  *ngIf="isSelectedDbDocuware">

                        <div class="select-menu">
                            <a href="javascript:void(0);" class="dropdown pass-info"
                                tooltip="How to get PassPhrase?" flow="down" (click)="op.toggle($event)"><img src="assets/img/info-ic.svg"
                                    alt="info" width="25"></a>


                                    <p-overlayPanel #op appendTo="body" styleClass="pass-info-box">
                                        <div class="password-info">
                                            <ul class="installation">
                                                <li>Login to the DocuWare web portal and click on the profile</li>
                                                <li>From profile, click on configuration, so Settings page will open</li>
                                                <li>In Settings page, from General panel, click on Organization Settings</li>
                                                <li>Click on Security tab</li>
                                                <li>Go to Passwords and copy the Passphrase</li>
                                                <li>Use this Passphrase in this field</li>
                                            </ul>
                                        </div>
                                    </p-overlayPanel>
                                </div>
                            </div>
                    <div class="form__group mb-0"  *ngIf="isSelectedDbDocuware">
                        <button class="btn-secondary mb-0" type="button" (click)="testConnection()">
                            Test connection
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="general-info popup-bottom">            
        <div class="button-block">
            <button class="btn-secondary" (click)="closePopup()" type="button">Cancel</button>
            <button class="btn-primary" type="button" (click)="onSubmit()">Save</button>
        </div>
    </div>

    <div class="loader-outer" *ngIf="loading">
        <div class="loader"></div>
      </div>
      
    </form>
</div>
