import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ResultOfT, Result } from '../models/response/result';
import { DocumentCabinetRequestModel } from '../models/request/document-cabinet-request';
import { DocumentCabinetResponseModel } from '../models/response/document-cabinet-response';
import { DocuwareCabinetReuestModel } from '../models/request/cabinet-request';
import { DocuwareRecordsModel } from '../models/response/docuware-fields';
import { ForgotPasswordModel } from '../models/response/forgot-password';
import { InceptionCustomAuthResponseModel } from '../models/response/custom-auth';
import { SearchableFieldResponseModel } from '../models/response/searchable-field-response';

@Injectable({
  providedIn: 'root'
})
export class DocuwareCabinetService extends BaseService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  fullTextSearch(reqModel: DocuwareCabinetReuestModel) {
    return this.post<ResultOfT<DocuwareRecordsModel[]>>('DocuwareCabinet/FullTextSearch' , reqModel);
  }

  getFileCabinetsFromDocuware(reqModel: DocuwareCabinetReuestModel) {
    return this.post<ResultOfT<DocumentCabinetResponseModel[]>>('DocuwareCabinet/GetFileCabinetsFromDocuware' , reqModel);
  }

  addDocumentCabinet(reqModel: DocumentCabinetResponseModel) {
    return this.post<ResultOfT<DocumentCabinetResponseModel>>('DocuwareCabinet/AddDocuwareCabinetInDb', reqModel);
  }

  updateDocumentCabinet(reqModel: DocumentCabinetResponseModel) {
    return this.post<ResultOfT<DocumentCabinetResponseModel>>('DocuwareCabinet/UpdateDocumentCabinetInDb', reqModel);
  }

  authenticateCustomPage(reqModel: ForgotPasswordModel) {
    return this.post<ResultOfT<InceptionCustomAuthResponseModel>>('DocuwareCabinet/AuthenticateCustomPage', reqModel);
  }

  getDocuwareFileCabinetRecords(reqModel: DocuwareCabinetReuestModel) {
    return this.post<ResultOfT<DocuwareRecordsModel[]>>('DocuwareCabinet/GetDocuwareFileCabinetRecords', reqModel);
  }

  getSearchControlValue(reqModel: DocuwareCabinetReuestModel) {
    return this.post<ResultOfT<DocuwareRecordsModel[]>>('DocuwareCabinet/GetSearchControlValue', reqModel);
  }

  syncFileCabinetMetadata(reqModel: DocuwareCabinetReuestModel) {
    return this.post<ResultOfT<DocumentCabinetResponseModel>>('DocuwareCabinet/SyncFileCabinetMetadata', reqModel);
  }

  generateDocumentViewerLink(reqModel: DocuwareCabinetReuestModel) {
    return this.post<ResultOfT<string>>('DocuwareCabinet/GenerateDocumentViewerLink', reqModel);
  }

  getSearchableFieldsByCabinetId(cabinetId: string) {
    return this.get<ResultOfT<SearchableFieldResponseModel[]>>('DocuwareCabinet/GetSearchableFieldsByCabinetId/'+ cabinetId);
  }
}
