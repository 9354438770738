import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../shared/base.component';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ForgotPasswordModel } from '../models/response/forgot-password';
import { ResultOfT } from '../models/response/result';
import { Constants } from '../shared/constants';
import { CustomValidator } from '../shared/custom-validator';
import { MigrationClientService } from '../services/migration-client.service';

@Component({
  selector: 'app-forgot-migration-client-password',
  templateUrl: './forgot-migration-client-password.component.html',
  styleUrls: ['./forgot-migration-client-password.component.sass']
})
export class ForgotMigrationClientPasswordComponent extends BaseComponent implements OnInit {

  title = 'DocuWare Migration Tool';
  h2title = 'Forgot Password?';

  displayMessage: boolean = false;
  message: string ='';
  messageClass: boolean;

  private unsubscriber = new Subject<boolean>();

  formFields = {  
    email: 'email',
  };

  validationMessages = {
    email: {
      required: 'Email is required',
      validemail: 'Email is invalid'
    }
  }

  constructor(private formBuilder: FormBuilder, private migrationService: MigrationClientService, private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.buildForm();
  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
  }
  onSubmit(){
    if (this.form.invalid) {
      this.markFormAsTouched();
      return;
    }

    const forgotPassword = new ForgotPasswordModel();
    forgotPassword.email = this.form.get(this.formFields.email).value;

    this.migrationService.forgotPassword(forgotPassword)
    .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
    .subscribe((result: ResultOfT<any>) => {
      this.processResult<any>(result, async () => {
        // debugger;
        if (result.IsSuccess) {
          // this.timeoutFunct("Password successfully sent on your email address.", true);
          this.timeoutFunct("Link to reset password is successfully sent to your email address.", true);
          setTimeout(() => {
            //Link to redirect shall be changed
            //this.router.navigate(['/login']);
          }, environment.messageLifeSpan);
        }else {
          this.timeoutFunct(result.Message, false);
        }
      });
    }, (httpResponseError) => {
      this.timeoutFunct(httpResponseError.message, false);
    });
  }

  timeoutFunct(msg,  isSuccess: boolean) {
    this.displayMessage =true;
    this.messageClass = isSuccess;
    this.message = msg;
    setTimeout(() => {
      this.message = '';
      this.displayMessage = false;
    }, environment.messageLifeSpan);
  }

  closeMessagePopup(){
    this.displayMessage = false;
  }


  private buildForm(){
    const formGroup = this.formBuilder.group({});

    formGroup.addControl(this.formFields.email, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.email], updateOn: Constants.formControlUpdateStrategy.blur }));

    this.form = formGroup;
  }

}
