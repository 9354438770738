// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiBaseUrl: 'https://api.doctainium.com/api/',
  baseUrl: 'https://api.doctainium.com/',
  webUrl: 'https://www.doctainium.com/',

  //apiBaseUrl: 'https://publicportal.inceptiontech.com/service/api/',
  //baseUrl: 'https://publicportal.inceptiontech.com/service/',
  //webUrl: 'https://publicportal.inceptiontech.com/',
  successMessageLifeTime: 5000,
  warningMessageLifeTime: 5000,
  errorMessageLifeTime: 10000,
  // In Minutes
  sessionTimeOut: 60,
  messageLifeSpan: 5000
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
